import React, { useEffect, useState } from 'react';
//import './assets/css/body.css'; // Import CSS from the theme
import { useParams } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { getCleanedDomainName } from './urlFetch';
import { SubscribeRibbon} from './SubscribeRibbon';
import {Helmet} from "react-helmet";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

function IndiaTempleTourWithId() {
  // Domain details Fetch
  const [domainName, setDomainName] = useState('');

  // Fetching the domain name
  useEffect(() => {
    setDomainName(getCleanedDomainName());
  }, []);

  console.log('Cleaned Domain Name:', domainName);

  const { id, title, packageId, templeId } = useParams();

  const [packageData, setPackageData] = useState({});
  const { 
    name
    ,subtitle
    ,brief
    ,description
    ,featured
    ,image_featured
    ,home_image_featured
    ,image
    ,micro_itinerary
    ,thumb
    ,location
    ,temples
    ,hoteles
    ,cities
    ,days
    ,nights
    ,tourtype
    ,price
    ,highlights
    ,transport_details
    ,includes_excludes
    ,reporting_dropping
    ,cancellation_policy
    ,important_info
    ,special_notes
    ,tourist_attractions
    ,eateries_shopping
    ,parent_id
    ,plan_name
    ,delete_status
   } = packageData;

  const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}tour/${packageId}`;
  console.log("packageURL:", apiUrl);

  useEffect(() => {
    fetch(apiUrl)
      .then(response => response.json())
      .then(data => setPackageData(data))
      .catch(error => console.error(error));
  }, [packageId]);

  const [microData, setMicroData] = useState({
    id: '',
    title: '',
    content: '',
  });

  console.log('packageId:', packageId);

  useEffect(() => {
    if(id){
        const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}microsite_data?inner_article_id=${id}`;
      console.log('API URL:', apiUrl);

      fetch(apiUrl)
        .then(response => response.json())
        .then(data => {
          console.log('API Response:', data);
          setMicroData(data[0]); // Assuming data is an array with a single item
        })
        .catch(error => console.error('API Error:', error));
    }
  }, [id]);

  //console.log('Name:', microData.name);
  //console.log('Domain:', microData.domain);


  const [swiperData, setSwiperData] = useState([]);

  useEffect(() => {
    if(templeId){
      const microDataUrl = `${process.env.REACT_APP_API_ENDPOINT}home_widget_ids?cat=temple_slides&id=${templeId}`;
      console.log('microDataUrl:', microDataUrl);
      // Replace with your API URL
      fetch(microDataUrl)
        .then((response) => response.json())
        .then((data) => setSwiperData(data))
        .catch((error) => console.error('Error fetching data:', error));
    }
  }, [templeId]);


  function stripHtmlTags(html) {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || '';
  }

  // Articles fetch starts
  const [p1, setP1] = useState({
    title: '',
    content: '',
    enquiry: '',
    content_type: '',
  });
  

  useEffect(() => {
    if(id){
      const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}temple_articles/${id}`;

      fetch(apiUrl)
        .then((response) => response.json())
        .then((data) => {
          const firstItem = data[0];

          if (firstItem && firstItem.title && firstItem.content) {
            setP1({
              title: firstItem.title,
              content: firstItem.content,
              enquiry: firstItem.enquiry,
              content_type: firstItem.content_type,
            });
          } else {
            console.error('Temple article data is incomplete.');
          }
        })
        .catch((error) => console.error('Error fetching data:', error));
    }
  }, [id]);

  let p1Refined = p1.title ? p1.title.toLowerCase().replace(/\s+/g, '-') : '';
  let p1URL = '';
  if(domainName == 'shirdisaitemple.com'){
    p1URL = `https://www.${domainName}.com/shirdi-article/id/${id}/${p1Refined}/`;
  }
  else{
    p1URL = `https://www.${domainName}.com/article/id/${id}/${p1Refined}/`;
  }

  const [tourPackages, setTourPackages] = useState([]);
  const [galleryImages, setGalleryImages] = useState([]);

  useEffect(() => {
    if(templeId){
      const tourPackagesURL = `${process.env.REACT_APP_API_ENDPOINT}microsite_tour_listings/${templeId}`;
      console.log("tourPackagesURL: ", tourPackagesURL);
      fetch(tourPackagesURL)
      .then(response => response.json())
      .then(data => setTourPackages(data))
      .catch(error => console.error(error));

    // Fetch gallery images
    const galleryImagesURL = `${process.env.REACT_APP_API_ENDPOINT}microsite_temple_galleries/${templeId}`;
    fetch(galleryImagesURL)
      .then(response => response.json())
      .then(data => setGalleryImages(data))
      .catch(error => console.error(error));

    }
  }, [templeId]);

  
  const [articleListings, setArticleListings] = useState([]);

  useEffect(() => {
    if(templeId){
      const articleListingsURL = `${process.env.REACT_APP_API_ENDPOINT}microsite_article_listings/${templeId}?all=1`;
      console.log("articleListingsURL: ", articleListingsURL);
      fetch(articleListingsURL)
      .then(response => response.json())
      .then(data => setArticleListings(data))
      .catch(error => console.error(error));
    }
  }, [templeId]);


  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    mobileNumber: '',
    noOfPersons: '',
    hotelType: '',
    startDate: '',
    endDate: '',
    message: '',
    graduationDay: ''
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleEnquirySubmit = (e) => {
    e.preventDefault();

    if (formData.graduationDay !== '') {
      // A value is present in the honeypot field, likely a bot
      return;
    }

    if (
      formData.fullName.trim() === '' ||
      formData.email.trim() === '' ||
      formData.mobileNumber.trim() === '' ||
      formData.noOfPersons.trim() === ''
    ) {
      alert('Please fill in all required fields.');
    } else {
      // Prepare the form data for submission
      const data = {
        fullName: formData.fullName,
        email: formData.email,
        mobileNumber: formData.mobileNumber,
        noOfPersons: formData.noOfPersons,
        hotelType: formData.hotelType,
        startDate: formData.startDate,
        endDate: formData.endDate,
        message: formData.message,
        website: domainName,
        page_title: p1.title
      };

      console.log("enquiry data: ", data);

      // Replace 'your-api-endpoint' with your actual API endpoint
      fetch(`${process.env.REACT_APP_API_ENDPOINT}enquiry_submission`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (response.ok) {
            // Handle a successful response here
            setIsSubmitted(true);
          } else {
            // Handle errors here
            console.error('Form submission failed');
          }
        })
        .catch((error) => {
          console.error('Error submitting the form:', error);
        });
    }
  };

  const currentUrl = window.location.href;

  // Calculate the minimum selectable date (2 days from today)
const checkInToday = new Date();
checkInToday.setDate(checkInToday.getDate() + 2);
const minCheckInDate = checkInToday.toISOString().split('T')[0];

const checkOutToday = new Date();
checkOutToday.setDate(checkOutToday.getDate() + 3);
const minCheckOutDate = checkOutToday.toISOString().split('T')[0];

  //Popup Form
const [showPopupForm, setshowPopupForm] = useState(false);

useEffect(() => {
  const timeout = setTimeout(() => {
    // Check if the popup was previously closed within the last 24 hours
    const popupClosedTime = localStorage.getItem('popupClosedTime');
    if (popupClosedTime) {
      const now = new Date().getTime();
      const elapsedTime = now - parseInt(popupClosedTime);
      if (elapsedTime >= 12 * 60 * 60 * 1000) {
        // If more than 12 hours have passed since the popup was closed, show it again
        setshowPopupForm(true);
      }
    } else {
      // If the popup has not been closed before, show it after 20 seconds
      setshowPopupForm(true);
    }
  }, 20000);

  return () => clearTimeout(timeout);
}, []);

const handleClosePopup = () => {
  setshowPopupForm(false);
  // Store in local storage that the user has closed the popup
  localStorage.setItem('popupClosed', 'true');
  // Store the time when the popup was closed
  const now = new Date().getTime();
  localStorage.setItem('popupClosedTime', now.toString());
};

function openPopup() {
  setshowPopupForm(true);
}

  return (
    <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>{name}</title>
      <link rel="canonical" href={currentUrl} />
    </Helmet>

    {showPopupForm && (
        <div className="position-fixed top-0 bottom-0 d-flex justify-content-center align-items-center w-75 popup_form">
          <div className="px-40 pt-35 pb-40 lg:px-30 lg:py-30 md:px-24 md:py-24 bg-white rounded-4 shadow-4">
              <div className="text-22 fw-500">Get the Best Holiday Planned by Our Experts!</div>
                <small>Fill your details below and we'll call you in a while.</small>
                <button type="button" className="close popup_form_close" aria-label="Close" onClick={handleClosePopup}>
                  <span aria-hidden="true">&times;</span>
                </button>
                <div className="row y-gap-15 pt-20">
                  <div className="col-md-6 col-sm-12 col-xs-12">
                    <div className="form-input">
                      <input
                        type="text"
                        name="fullName"
                        value={formData.fullName}
                        onChange={handleChange}
                        required
                      />
                      <label className="lh-1 text-16 text-light-1">Full Name*</label>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 col-xs-12">
                    <div className="form-input">
                      <input
                        type="text"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                      <label className="lh-1 text-16 text-light-1">Email Address*</label>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 col-xs-12">
                    <div className="form-input">
                      <input
                        type="text"
                        name="mobileNumber"
                        value={formData.mobileNumber}
                        onChange={handleChange}
                        required
                      />
                      <label className="lh-1 text-16 text-light-1">Mobile Number*</label>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-input">
                      <input
                        type="text"
                        name="noOfPersons"
                        value={formData.noOfPersons}
                        onChange={handleChange}
                        required
                      />
                      <label className="lh-1 text-16 text-light-1">No. of Person*</label>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-input" style={{minHeight:'70px'}}>
                    <label className="lh-1 text-16 text-light-1" style={{top:'12px'}}>Hotel Type</label>
                      <select
                        style={{
                          border: '1px solid #DDDDDD',
                          borderRadius:'4px',
                          padding: '0 15px',
                          paddingTop:'25px'}}
                        name="hotelType"
                        value={formData.hotelType}
                        onChange={handleChange}
                      >
                      <option value="3 Star">3 Star</option>
                      <option value="4 Star">4 Star</option>
                      <option value="5 Star">5 Star</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-input">
                      <input
                        type="date"
                        name="startDate"
                        value={formData.startDate}
                        min={minCheckInDate}
                        onChange={handleChange}
                        required
                      />
                      <label className="lh-1 text-16 text-light-1" style={{top:'15px'}}>Start Date</label>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-input">
                      <input
                        type="date"
                        name="endDate"
                        value={formData.endDate}
                        min={minCheckOutDate}
                        onChange={handleChange}
                        required
                      />
                      <label className="lh-1 text-16 text-light-1" style={{top:'15px'}}>End Date</label>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 col-xs-12">
                    <div className="form-input">
                      <textarea
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        required
                        rows="1"
                      ></textarea>
                      <label className="lh-1 text-16 text-light-1">Message (If Any)</label>
                    </div>
                  </div>
                  <div style={{ display: 'none' }}>
                    <input
                      type="text"
                      name="graduationDay"
                      value={formData.graduationDay}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-auto">
                    <button
                      onClick={handleEnquirySubmit}
                      className="button px-24 h-50 -dark-1 bg-blue-1 text-white"
                    >
                      Get a Callback
                      <div className="icon-arrow-top-right ml-15"></div>
                    </button>
                  </div>
                  <div className="col-12">
                    {isSubmitted && <p style={{color:'green'}}>Thanks for submitting your details, we will get back to you shortly!</p>}
                  </div>
                </div>
            </div>
        </div>
    )}

    <div class="floating-section">
        <div class="floating-option">
          <a style={{cursor:'pointer'}} onClick={openPopup}>
            <img src={`https://blessingsonthenet.com/assets/img/general/telephone.png`} />
            Get a Callback
          </a>
        </div>
        <div class="floating-option">
            <a href="https://wa.me/919892002006" target="_blank" rel="noopener noreferrer">
              <img src={`https://blessingsonthenet.com/assets/img/general/whatsapp.png`} />
              Chat on WhatsApp
            </a>
        </div>
    </div>

    {/* Slider section */}
    <section className="z-5">
      <div className="swiper-wrapper">
        <Swiper
          spaceBetween={30}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          slidesPerView={1}
          loop={true}
          modules={[Autoplay]}
          className="mySwiper"
        >
          {swiperData.map((item) => (
            <SwiperSlide key={item.id}>
              <img style={{width:'100%'}} src={
              item.image.includes('aim_')
              ? `https://blessingsonthenet.com/img/slideshow/${item.image}`
              : `https://api.blessingsonthenet.com/uploads/microsite/${item.image}`
            } alt={`Slide ${item.id}`} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>

    {/* The 3 articles section */}

    <section class="section-bg pt-30 pb-30">
      <div class="section-bg__item -mx-20"></div>

      <div data-anim-wrap class="container">
        <div class="row y-gap-30">
          {p1.content_type === 'article' ? (
            <div class="col-xl-12">
              <div id="overview" class="col-12">
                <h1 class="sectionTitle__title text-26 mb-30">{p1.title}</h1>
                <div dangerouslySetInnerHTML={{ __html: p1.content }} />
                {/*<a href="#" class="d-block text-14 text-blue-1 fw-500 underline mt-10">Show More</a>*/}
              </div>
            </div>
          ) 
          : (
            <>
            <div class="col-xl-8">
              <div id="overview" class="col-12">
                <h1 class="sectionTitle__title text-26 mb-30">{name}</h1>
                <div dangerouslySetInnerHTML={{ __html: brief }} />
                <div dangerouslySetInnerHTML={{ __html: description }} />
                {/*<a href="#" class="d-block text-14 text-blue-1 fw-500 underline mt-10">Show More</a>*/}
              </div>
            </div>
            <div class="col-xl-4">      

                <div class="ml-50 lg:ml-0">

                    <div class="px-30 pt-10 pb-20 border-light rounded-4 shadow-4" id="book-now">
                        <div class="d-flex items-center justify-between">
                            <div class="">
                            <span class="text-20 fw-500">Book Now</span>
                            </div>
                        </div>
                        
                        <div className="text-center">
                            <br />
                            <h6 style={{color:'#FF0000'}}>WhatsApp for Immediate Response and Customization</h6>
                            <br />
                            <a href="https://wa.me/919892002006">+91 9892 002 006</a><br />
                            <a href="https://wa.me/919224444455">+91 9224 444 455</a><br />
                            <a href="https://wa.me/919987404440">+91 9987 404 440</a>
                            {/*
                            <a href="https://wa.me/919892002006">+91 9892 002 006</a><br />
                            <a href="https://wa.me/919224444455">+91 9224 444 455</a><br />
                            <a href="https://wa.me/919987404440">+91 9987 404 440</a>
                            */}
                            <a href="https://wa.me/919892002006">+91 9892 002 006</a><br />
                            <a href="https://wa.me/919224444455">+91 9224 444 455</a><br />
                            <a href="https://wa.me/919987404440">+91 9987 404 440</a>
                            <br /><br />
                            <h6 style={{color:'#FF0000'}}>Send Us Email</h6>
                            <a href="mailto:travel@blessingsonthenet.com">travel@blessingsonthenet.com</a><br /><br />
                            {/*
                            <a href={`https://www.blessingsonthenet.com/hotel/${refined_url}/${id}`} class="button h-50 px-24 -dark-1 bg-blue-1 text-white">
                                Book Now
                                <div class="icon-arrow-top-right ml-15"></div>
                            </a><br />
                            */}
                        </div>

                    </div>

                </div>
            </div>
            </>
          )}
        </div>
        
      </div>
    </section>

    {/* Enquiry Form */}
    {p1.enquiry === "1" ? (
    <section class="layout-pt-lg layout-pb-md mt-50">
      <div data-anim-wrap="" class="container animated">
        <div data-anim-child="slide-up delay-1" class="row justify-center text-center is-in-view">
          <div class="col-auto">
            <div class="sectionTitle -md">
              <h2 class="sectionTitle__title">Enquire with Us</h2>
              <p class=" sectionTitle__text mt-5 sm:mt-0">Have any questions? feel free to get in touch with us and we will answer all your questions.</p>
            </div>
          </div>
        </div>

        <div class="container-fluid">
          <div class="row">
            <div class="col-md-6">
              <div style={{backgroundColor: '#13357B', color: '#fff',}} class="mt-50 px-40 pt-30 pb-30 lg:px-30 lg:py-30 md:px-24 md:py-24 rounded-4 shadow-4">
                <div class="card-body">
                  <h4 style={{color: '#fff',}} class="card-title">WhatsApp Us</h4><br />
                  <p style={{color: '#fff',}} class="card-text">Request a quote, or just chat about your next vacation. We're always happy to help!</p><br />
                  {/*
                  <a target="_blank" href="https://wa.me/919892002006"><h5 style={{color: '#fff',}} class="card-text mb-10"><img src="/assets/img/icons/phone.png" style={{width:'25px'}} /> +91 9892 002 006</h5></a>
                  <a target="_blank" href="https://wa.me/919224444455"><h5 style={{color: '#fff',}} class="card-text mb-10"><img src="/assets/img/icons/phone.png" style={{width:'25px'}} /> +91 9224 444 455</h5></a>
                  <a target="_blank" href="https://wa.me/919987404440"><h5 style={{color: '#fff',}} class="card-text mb-10"><img src="/assets/img/icons/phone.png" style={{width:'25px'}} /> +91 9987 404 440</h5></a>
                  */}
                  <a target="_blank" href="https://wa.me/919892002006"><h5 style={{color: '#fff',}} class="card-text mb-10"><img src="/assets/img/icons/phone.png" style={{width:'25px'}} /> +91 9892 002 006</h5></a>
                  <a target="_blank" href="https://wa.me/919224444455"><h5 style={{color: '#fff',}} class="card-text mb-10"><img src="/assets/img/icons/phone.png" style={{width:'25px'}} /> +91 9224 444 455</h5></a>
                  <a target="_blank" href="https://wa.me/919987404440"><h5 style={{color: '#fff',}} class="card-text mb-10"><img src="/assets/img/icons/phone.png" style={{width:'25px'}} /> +91 9987 404 440</h5></a>
                </div>
              </div>
              <div style={{backgroundColor: '#ff894c', color:'#051036'}} class="mt-50 px-40 pt-40 pb-50 lg:px-30 lg:py-30 md:px-24 md:py-24 rounded-4 shadow-4">
                <div class="card-body">
                  <h4 style={{color:'#051036'}} class="card-title">Write to Us</h4><br />
                  <p style={{color:'#051036'}} class="card-text">Do you like to customize this hotel stay even more or be it an enquiry, feedback or a simple suggestion, write to us.</p><br />
                  <a target="_blank" href="mailto:travel@blessingsonthenet.com"><h5 style={{color:'#051036'}} class="card-text"><img src="/assets/img/icons/email.png" style={{width:'25px'}} /> travel@blessingsonthenet.com</h5></a>
                </div>
              </div>
            </div>
            <div class="col-md-6">
            <div className="mt-50 px-40 pt-35 pb-40 lg:px-30 lg:py-30 md:px-24 md:py-24 bg-white rounded-4 shadow-4">
              <div className="text-22 fw-500">Enter your journey details for travel enquiry.</div>
                <div className="row y-gap-15 pt-20">
                  <div className="col-12">
                    <div className="form-input">
                      <input
                        type="text"
                        name="fullName"
                        value={formData.fullName}
                        onChange={handleChange}
                        required
                      />
                      <label className="lh-1 text-16 text-light-1">Full Name*</label>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-input">
                      <input
                        type="text"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                      <label className="lh-1 text-16 text-light-1">Email Address*</label>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-input">
                      <input
                        type="text"
                        name="mobileNumber"
                        value={formData.mobileNumber}
                        onChange={handleChange}
                        required
                      />
                      <label className="lh-1 text-16 text-light-1">Mobile Number*</label>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-input">
                      <input
                        type="text"
                        name="noOfPersons"
                        value={formData.noOfPersons}
                        onChange={handleChange}
                        required
                      />
                      <label className="lh-1 text-16 text-light-1">No. of Person*</label>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-input" style={{minHeight:'70px'}}>
                    <label className="lh-1 text-16 text-light-1" style={{top:'12px'}}>Hotel Type</label>
                      <select
                        style={{
                          border: '1px solid #DDDDDD',
                          borderRadius:'4px',
                          padding: '0 15px',
                          paddingTop:'25px'}}
                        name="hotelType"
                        value={formData.hotelType}
                        onChange={handleChange}
                      >
                      <option value="3 Star">3 Star</option>
                      <option value="4 Star">4 Star</option>
                      <option value="5 Star">5 Star</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-input">
                      <input
                        type="date"
                        name="startDate"
                        value={formData.startDate}
                        onChange={handleChange}
                        required
                      />
                      <label className="lh-1 text-16 text-light-1" style={{top:'15px'}}>Start Date</label>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-input">
                      <input
                        type="date"
                        name="endDate"
                        value={formData.endDate}
                        onChange={handleChange}
                        required
                      />
                      <label className="lh-1 text-16 text-light-1" style={{top:'15px'}}>End Date</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-input">
                      <textarea
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        required
                        rows="2"
                      ></textarea>
                      <label className="lh-1 text-16 text-light-1">Message (If Any)</label>
                    </div>
                  </div>
                  <div style={{ display: 'none' }}>
                    <input
                      type="text"
                      name="graduationDay"
                      value={formData.graduationDay}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-auto">
                    <button
                      onClick={handleEnquirySubmit}
                      className="button px-24 h-50 -dark-1 bg-blue-1 text-white"
                    >
                      Send a Message
                      <div className="icon-arrow-top-right ml-15"></div>
                    </button>
                  </div>
                  <div className="col-12">
                    {isSubmitted && <p style={{color:'green'}}>Thanks for submitting your details, we will get back to you shortly!</p>}
                  </div>
                </div>
            </div>
            </div>
          </div>
        </div>
        
      </div>
    </section>
    ) : ''}

    {/* Holidays Section */}
    <section className="layout-pt-lg layout-pb-md">
      <div className="container">
        <div data-anim="slide-up delay-1" className="row y-gap-20 justify-between items-end is-in-view">
          <div className="col-auto">
            <div className="sectionTitle -md">
              <h2 className="sectionTitle__title">Related Tour Packages</h2>
            </div>
          </div>

          <div className="col-auto md:d-none">

            <a href="https://www.blessingsonthenet.com/travels/travel/page/1" className="button -md -blue-1 bg-blue-1-05 text-blue-1">
              View All Holidays <div className="icon-arrow-top-right ml-15"></div>
            </a>

          </div>
        </div>

        <div className="relative pt-40 sm:pt-20 js-section-slider swiper-initialized swiper-horizontal swiper-pointer-events swiper-autoheight swiper-watch-progress swiper-backface-hidden is-in-view">
          <div className="swiper-wrapper">
            <Swiper
              spaceBetween={30}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              slidesPerView={3}
              loop={true}
              navigation={true}
              modules={[Autoplay, Navigation]}
              className="mySwiper"
            >
            {tourPackages.map((hpackage, index) => (
              <SwiperSlide>
                <div className="swiper-slide swiper-slide-visible swiper-slide-active" key={hpackage.id} 
                style={{marginRight: '30px'}}>
                  <a href={`/${domainName === 'shirdisaitemple.com' ? 'shirdi-article' : 'article'}/id/${hpackage.id}/temple/${templeId}/${hpackage.title.toLowerCase().replace(/\s+/g, '-')}`} className="citiesCard -type-1 d-block rounded-4 ">
                    <div className="citiesCard__image ratio ratio-2:1">
                      {galleryImages[index % galleryImages.length] && (
                        <img src={`https://blessingsonthenet.com/img/gallery/${galleryImages[index % galleryImages.length].thumb}`} alt={hpackage.title} />
                      )}
                      {/*
                      <img
                        src={hpackage.thumb_new ? `https://api.blessingsonthenet.com/uploads/${hpackage.thumb_new.replace(/ /g, '%20')}` : `https://www.blessingsonthenet.com/img/uploads/tour/thumbs/${hpackage.thumb}`}
                        data-src={hpackage.thumb_new ? `https://api.blessingsonthenet.com/uploads/${hpackage.thumb_new.replace(/ /g, '%20')}` : `https://www.blessingsonthenet.com/img/uploads/tour/thumbs/${hpackage.thumb}`}
                        alt="image"
                      />
                      */}
                    </div>

                    <div className="citiesCard__content d-flex flex-column justify-between text-center pt-30 pb-20 px-20">
                      <div className="citiesCard__bg"></div>

                      {/*
                      <div className="citiesCard__top">
                        <div className="text-14 text-white">{hpackage.nights} Night {hpackage.days} Days</div>
                      </div>
                      */}

                      <div className="citiesCard__bottom">
                        <h4 className="text-16 md:text-20 lh-13 text-white mb-10">{hpackage.title}</h4>
                        {/*<p className="starts_from">Starts from ₹{hpackage.price}/-</p>*/}
                        <button className="button col-12 h-40 -blue-1 bg-white text-dark-1">View Details</button>
                      </div>
                    </div>
                  </a>

                </div>
              </SwiperSlide>
            ))}
            </Swiper>
          </div>

          <div className="row pt-20 d-none md:d-block">
            <div className="col-auto">
              <div className="d-inline-block">

                <a href="#" className="button -md -blue-1 bg-blue-1-05 text-blue-1">
                  View All Destinations <div className="icon-arrow-top-right ml-15"></div>
                </a>

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    {/* Articles Section */}
    <section class="layout-pt-lg layout-pb-md relative" id="secondSection">
      <div data-anim-wrap="" class="container animated">
        <div data-anim-child="slide-up delay-1" class="row y-gap-20 justify-center text-center is-in-view">
          <div class="col-auto">
            <div class="sectionTitle -md">
              <h2 class="sectionTitle__title">Articles</h2>
              {/*<p class=" sectionTitle__text mt-5 sm:mt-0">Here are list of nearby places that you might be intersted in</p>*/}
            </div>
          </div>
        </div>

        <div class="row x-gap-10 y-gap-10 pt-40 sm:pt-20">

          {articleListings.map((temple) => (
            <div key={temple.id} data-anim-child="slide-up delay-4" class="col-xl-4 col-lg-3 col-6 is-in-view">
              <a href={`/${domainName === 'shirdisaitemple.com' ? 'shirdi-article' : 'article'}/id/${temple.id}/temple/${templeId}/${temple.title.toLowerCase().replace(/\s+/g, '-')}`} class="citiesCard -type-5 d-flex items-center sm:flex-column sm:items-start px-20 py-20 sm:px-15 sm:py-20 bg-light-2 rounded-4">
                <div class="ml-10 sm:ml-0 sm:mt-10">
                  <h4 title={temple.title} class="text-16 fw-500"> {temple.title.length > 40 ? `${temple.title.substring(0, 40)}...` : temple.title}</h4>
                </div>
              </a>
            </div>
          ))}

        </div>
      </div>
    </section>

    <SubscribeRibbon />

    </>
  );
}

export default IndiaTempleTourWithId;
import React, { useState, useEffect } from 'react';
//import './assets/css/footer.css'; // Import CSS from the theme
import { getCleanedDomainName } from './urlFetch';

function Footer() {
  const isAdmin = window.location.pathname.includes('/admin');

    // Domain details Fetch
    const [domainName, setDomainName] = useState('');

    // Fetching the domain name
    useEffect(() => {
      setDomainName(getCleanedDomainName());
    }, []);

    const [microData, setMicroData] = useState({
      footer_content: '',
  });
  const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}microsite_data/${domainName}`;

  useEffect(() => {
      if(domainName){
      console.log('API URL:', apiUrl);

      fetch(apiUrl)
          .then(response => response.json())
          .then(data => {
          console.log('API Response:', data);
            if (data && data.length > 0) {
              setMicroData(data[0]); // Assuming data is an array with a single item
            }
          })
          .catch(error => console.error('API Error:', error));
      }
  }, [domainName, apiUrl]);

  console.log('Footer Content:', microData.footer_content);

  if(!isAdmin){
    return (
      <footer class="footer -type-1">
        <div class="container">
          <div class="pt-60 pb-60">
            <div class="row y-gap-40 justify-between xl:justify-start">
              <div class="col-xl-2 col-lg-4 col-sm-6">
                <h5 class="text-16 fw-500 mb-30">Contact Us</h5>

                <div class="mt-30">
                  {/*
                  <div class="text-14 mt-30">WhatsApp</div>
                  <a href="https://wa.me/918452040404" class="text-18 fw-500 text-blue-1 mt-5">8452 040 404</a><br />
                  <a href="https://wa.me/919224444455" class="text-18 fw-500 text-blue-1 mt-5">9224 444 455</a><br />
                  <a href="https://wa.me/919987404440" class="text-18 fw-500 text-blue-1 mt-5">9987 404 440</a>
                  */}
                  <div class="text-14 mt-30">Call Us</div>
                  <a href="https://wa.me/918452040404" class="text-18 fw-500 text-blue-1 mt-5">8452 040 404</a><br />
                  <a href="https://wa.me/919224444455" class="text-18 fw-500 text-blue-1 mt-5">9224 444 455</a><br />
                  <a href="https://wa.me/919987404440" class="text-18 fw-500 text-blue-1 mt-5">9987 404 440</a>
                </div>

                <div class="mt-35">
                  <div class="text-14 mt-30">Our Email Address</div>
                  <a href="mailto:travel@blessingsonthenet.com" class="text-18 fw-500 text-blue-1 mt-5">travel@blessingsonthenet.com</a>
                </div>
              </div>

              <div class="col-xl-2 col-lg-4 col-sm-6">
                <h5 class="text-16 fw-500 mb-30">Our Address</h5>
                <p class="text-15 fw-500">Nirvana India Enterprise<br />C- 401 Umang Vasant Utsav, Thakur Village, Kandivali East, Mumbai - 400101, India</p>
              </div>

              <div class="col-xl-4 col-lg-4 col-sm-6">
                {/*
                <h5 class="text-16 fw-500 mb-30">Support</h5>
                <div class="d-flex y-gap-10 flex-column">
                  <a href="#">Terms & Conditions</a>
                  <a href="#">Privacy Policy</a>
                  <a href="#">Contact</a>
                  <a href="#">Sitemap</a>
                </div>
                */}
                <h5 class="text-16 fw-500 mb-30">Disclaimer</h5>
                <p class="text-14 fw-500">{domainName} is the associate of Nirvana India Enterprise and it is not the official website of any temple. We are not representing any temple or trust or any associate of the Official Temple. This is a private and commercial site and has no link, relationship to the actual temple's trust.</p>
                {microData.footer_content !== null ? (
                  <p class="text-14 fw-500" dangerouslySetInnerHTML={{ __html: microData.footer_content }} />
                ) : (
                  ''
                )}
              </div>

              <div class="col-xl-2 col-lg-4 col-sm-6">
                <h5 class="text-16 fw-500 mb-30">Our Other Sites</h5>
                <div class="d-flex y-gap-10 flex-column">
                  <a target="_blank" href="https://www.blessingsonthenet.com">Blessingsonthenet.com</a>
                  <a target="_blank" href="https://dwarkadishtemple.co.in">Dwarkadishtemple.co.in</a>
                  <a target="_blank" href="https://rishikeshtemple.com">Rishikeshtemple.com</a>
                  <a target="_blank" href="https://indiatemple.net">Indiatemple.net</a>
                  
                </div>

                <div class="mt-30 ml-10">
                  <div class="col-auto">
                    <div class="d-flex x-gap-20 items-center">
                      Follow Us:
                      <a target="_blank" href="https://www.facebook.com/NirvanaIndiaEnterprise"><i class="icon-facebook text-14"></i></a>
                      <a target="_blank" href="https://www.instagram.com/nirvana.india.enterprise/"><i class="icon-instagram text-14"></i></a>
                    </div>
                  </div>
                </div>
              </div>

              
            </div>
          </div>

          <div class="py-20 border-top-light">
            <div class="row justify-between items-center y-gap-10">
              <div class="col-auto">
                <div class="row x-gap-30 y-gap-10">
                  <div class="col-auto">
                    <div class="d-flex items-center">
                      © {new Date().getFullYear()} Nirvana India Enterprise
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
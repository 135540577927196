import React, { useState, useEffect } from 'react';
import { getCleanedDomainName } from './urlFetch';
import { GroupHeader } from './GroupHeader';
import { HeaderIndividual } from './HeaderIndividual';

function Header() {
  const [domainName, setDomainName] = useState('');
  const [micrositeData, setMicrositeData] = useState([]);
  const [isGroupMicrosite, setIsGroupMicrosite] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setDomainName(getCleanedDomainName());
  }, []);

  useEffect(() => {
    // Fetch microsite data from the API
    const groupCheck = `${process.env.REACT_APP_API_ENDPOINT}microsite_data/all?cat=group`;
    fetch(groupCheck)
      .then(response => response.json())
      .then(data => {
        setMicrositeData(data);
        setLoading(false); // API call is completed
      })
      .catch(error => {
        console.error('Error fetching microsite data:', error);
        setLoading(false); // Handle API error
      });
  }, []);

  useEffect(() => {
    // Check if the domain matches any item in micrositeData
    setIsGroupMicrosite(micrositeData.some(item => domainName.includes(item.domain)));
  }, [domainName, micrositeData]);

  console.log("isGroupMicrosite: ", isGroupMicrosite);

  if (loading) {
    // Display a loading indicator or placeholder here
    return <div>Loading...</div>;
  }

  return (
    <>
      {/*{isGroupMicrosite ? <GroupHeader /> : <HeaderIndividual />}*/}
      {/*<HeaderIndividual />*/}
      <GroupHeader />
    </>
  );
}

export default Header;
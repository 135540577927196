import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
//import './assets/css/body.css'; // Import CSS from the theme
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { getCleanedDomainName } from './urlFetch';
import { SubscribeRibbon} from './SubscribeRibbon';
import {Helmet} from "react-helmet";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

function GroupProductListings() {
  // Domain details Fetch
  const [domainName, setDomainName] = useState('');

  // Fetching the domain name
  useEffect(() => {
    setDomainName(getCleanedDomainName());
  }, []);

  console.log('Cleaned Domain Name:', domainName);

  const { id, title } = useParams();

  const [microData, setMicroData] = useState({
    id: '',
    name: '',
    domain: '',
    p1: '',
    p2: '',
    p3: '',
    place: '',
  });

  useEffect(() => {
    if(id){
        const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}microsite_data?article_id=${id}`;
      console.log('API URL:', apiUrl);

      fetch(apiUrl)
        .then(response => response.json())
        .then(data => {
          console.log('API Response:', data);
          setMicroData(data[0]); // Assuming data is an array with a single item
        })
        .catch(error => console.error('API Error:', error));
    }
  }, [id]);

  //console.log('Name:', microData.name);
  //console.log('Domain:', microData.domain);
  console.log('P1:', microData.p1);
  console.log('P2:', microData.p2);
  console.log('P3:', microData.p3);


  const [swiperData, setSwiperData] = useState([]);
  
  useEffect(() => {
    if(id){
      const microDataUrl = `${process.env.REACT_APP_API_ENDPOINT}microsite_data?article_id=${id}`;
      console.log('microDataUrl:', microDataUrl);
      // Replace with your API URL
      fetch(microDataUrl)
        .then((response) => response.json())
        .then((data) => setSwiperData(data))
        .catch((error) => console.error('Error fetching data:', error));
    }
  }, [id]);


  function stripHtmlTags(html) {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || '';
  }



  const [tourPackages, setTourPackages] = useState([]);
  const [galleryImages, setGalleryImages] = useState([]);

  useEffect(() => {
    if(id){
      const tourPackagesURL = `${process.env.REACT_APP_API_ENDPOINT}microsite_tour_listings/${id}`;
      console.log("tourPackagesURL: ", tourPackagesURL);
      fetch(tourPackagesURL)
      .then(response => response.json())
      .then(data => setTourPackages(data))
      .catch(error => console.error(error));

    // Fetch gallery images
    const galleryImagesURL = `${process.env.REACT_APP_API_ENDPOINT}microsite_temple_galleries/${id}`;
    fetch(galleryImagesURL)
      .then(response => response.json())
      .then(data => setGalleryImages(data))
      .catch(error => console.error(error));

    }
  }, [id]);


  const [hotelData, setHotelData] = useState([]);

  useEffect(() => {
    if(microData.place){
      const hotelDataURL = `${process.env.REACT_APP_API_ENDPOINT}hotel_listings?city=${microData.place}`;
      console.log("hotelDataURL: ", hotelDataURL);
      fetch(hotelDataURL)
      .then(response => response.json())
      .then(data => setHotelData(data))
      .catch(error => console.error(error));
    }
  }, [microData.place]);


  const [productData, setProductData] = useState([]);

  useEffect(() => {
    const productDataURL = `${process.env.REACT_APP_API_ENDPOINT}product_listings?id=336,337,340,341,342,343,353,357,363,364,366,369`;
      console.log("productDataURL: ", productDataURL);
      fetch(productDataURL)
      .then(response => response.json())
      .then(data => setProductData(data))
      .catch(error => console.error(error));
  }, []);


  const [templesData, setTemplesData] = useState([]);

  useEffect(() => {
      const templesDataURL = `${process.env.REACT_APP_API_ENDPOINT}temples_listings?microsite_temples=1`;
      console.log("templesDataURL: ", templesDataURL);
      fetch(templesDataURL)
      .then(response => response.json())
      .then(data => setTemplesData(data))
      .catch(error => console.error(error));
  }, []);

  
  const [articleListings, setArticleListings] = useState([]);

  useEffect(() => {
    if(microData.id){
      const articleListingsURL = `${process.env.REACT_APP_API_ENDPOINT}microsite_article_listings/${microData.id}`;
      console.log("articleListingsURL: ", articleListingsURL);
      fetch(articleListingsURL)
      .then(response => response.json())
      .then(data => setArticleListings(data))
      .catch(error => console.error(error));
    }
  }, [microData.id]);


  const [productListings, setProductListings] = useState([]);

  useEffect(() => {
    const productsAPIURL = `${process.env.REACT_APP_API_ENDPOINT}product_listings?id=336,337,340,341,342,343,353,357,363,364,366,369`;
    console.log("productsAPIURL: ", productsAPIURL);      
  
    fetch(productsAPIURL)
      .then((response) => response.json())
      .then((data) => {
          setProductListings(data);
      })
      .catch((error) => console.log(error));
  }, []);
  
  const currentUrl = window.location.href;

  return (
    <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>{microData.name} Featured Products</title>
      <link rel="canonical" href={currentUrl} />
    </Helmet>
    {/* Slider section */}
    <section className="z-5">
      <div className="swiper-wrapper">
        <Swiper
          spaceBetween={30}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          slidesPerView={1}
          loop={true}
          modules={[Autoplay]}
          className="mySwiper"
        >
          {swiperData.map((item) => (
            <SwiperSlide key={item.id}>
              <img style={{width:'100%'}} src={`https://blessingsonthenet.com/img/uploads/${item.image}`} alt={`Slide ${item.id}`} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>

    {/* Products Section */}
    <section className="layout-pt-lg layout-pb-md mt-30">
      <div className="container">
        <div data-anim="slide-up delay-1" className="row y-gap-20 justify-between items-end is-in-view">
          <div className="col-auto">
            <div className="sectionTitle -md">
              <h2 className="sectionTitle__title">Featured Products</h2>
            </div>
          </div>

          <div className="col-auto md:d-none">

            <a href="https://www.blessingsonthenet.com/products/page/1" className="button -md -blue-1 bg-blue-1-05 text-blue-1">
              View All Products <div className="icon-arrow-top-right ml-15"></div>
            </a>

          </div>
        </div>

        <div class="row y-gap-30 pt-40 sm:pt-20">

            {productListings.map(list => (
                <div class="col-xl-3 col-lg-3 col-sm-6 mt-10">

                <a href={`/product/${list.product_name.toLowerCase().replace(/\s+/g, '-')}/${list.id}`} class="hotelsCard -type-1">

                <div class="hotelsCard__image">

                    <div class="cardImage ratio ratio-1:1">
                    <div class="cardImage__content">

                        <img class="rounded-4 col-12" src={`https://api.blessingsonthenet.com/uploads/shopping/${list.image_src}`} alt="image" />

                    </div>

                    </div>

                </div>

                <div class="hotelsCard__content mt-10 text-center">
                    <h4 class="hotelsCard__title text-dark-1 text-18 lh-16 fw-500">
                    <span>{list.product_name}
                        </span>
                    </h4>

                    {/*<p class="text-light-1 lh-14 text-14 mt-5">{list.category_name}</p>*/}

                    <div>
                        <div class="fw-500">
                            {list.price > 0 ?
                            <span dangerouslySetInnerHTML={{ __html: `<span class="text-blue-1">₹${list.price}</span>` }}></span> : 'Contact us for Price'
                            }
                        </div>
                    </div>
                </div>
                </a>

            </div>
            ))}

        </div>

      </div>
    </section>

    {/* Holidays Section */}
    <section className="layout-pt-lg layout-pb-md">
      <div className="container">
        <div data-anim="slide-up delay-1" className="row y-gap-20 justify-between items-end is-in-view">
          <div className="col-auto">
            <div className="sectionTitle -md">
              <h2 className="sectionTitle__title">Tour Packages</h2>
            </div>
          </div>

          <div className="col-auto md:d-none">

            <a href="https://www.blessingsonthenet.com/travels/travel/page/1" className="button -md -blue-1 bg-blue-1-05 text-blue-1">
              View All Holidays <div className="icon-arrow-top-right ml-15"></div>
            </a>

          </div>
        </div>

        <div className="relative pt-40 sm:pt-20 js-section-slider swiper-initialized swiper-horizontal swiper-pointer-events swiper-autoheight swiper-watch-progress swiper-backface-hidden is-in-view">
          <div className="swiper-wrapper">
            <Swiper
              spaceBetween={30}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              loop={true}
              navigation={true}
              modules={[Autoplay, Navigation]}
              className="mySwiper"
              breakpoints={{
                // When window width is <= 767px (typically mobile phones), show 2 slides
                767: {
                  slidesPerView: 2,
                },
                // Default: When window width is > 767px, show 4 slides
                // You can adjust this breakpoint based on your design requirements
                768: {
                  slidesPerView: 3,
                },
              }}
            >
            {tourPackages.map((hpackage, index) => (
              <SwiperSlide>
                <div className="swiper-slide swiper-slide-visible swiper-slide-active" key={hpackage.id} 
                style={{marginRight: '30px'}}>
                  <a href={`/${domainName === 'shirdisaitemple.com' ? 'shirdi-article' : 'article'}/id/${hpackage.id}/temple/${id}/${hpackage.title.toLowerCase().replace(/\s+/g, '-')}`} className="citiesCard -type-1 d-block rounded-4 ">
                    <div className="citiesCard__image ratio ratio-4:3">
                      {galleryImages[index] && (
                        <img src={`https://blessingsonthenet.com/img/gallery/${galleryImages[index].thumb}`} alt={hpackage.title} />
                      )}
                      {/*
                      <img
                        src={hpackage.thumb_new ? `https://api.blessingsonthenet.com/uploads/${hpackage.thumb_new.replace(/ /g, '%20')}` : `https://www.blessingsonthenet.com/img/uploads/tour/thumbs/${hpackage.thumb}`}
                        data-src={hpackage.thumb_new ? `https://api.blessingsonthenet.com/uploads/${hpackage.thumb_new.replace(/ /g, '%20')}` : `https://www.blessingsonthenet.com/img/uploads/tour/thumbs/${hpackage.thumb}`}
                        alt="image"
                      />
                      */}
                    </div>

                    <div className="citiesCard__content d-flex flex-column justify-between text-center pt-30 pb-20 px-20">
                      <div className="citiesCard__bg"></div>

                      {/*
                      <div className="citiesCard__top">
                        <div className="text-14 text-white">{hpackage.nights} Night {hpackage.days} Days</div>
                      </div>
                      */}

                      <div className="citiesCard__bottom">
                        <h4 className="text-16 md:text-20 lh-13 text-white mb-10">{hpackage.title}</h4>
                        {/*<p className="starts_from">Starts from ₹{hpackage.price}/-</p>*/}
                        <button className="button col-12 h-40 -blue-1 bg-white text-dark-1">View Details</button>
                      </div>
                    </div>
                  </a>

                </div>
              </SwiperSlide>
            ))}
            </Swiper>
          </div>

          <div className="row pt-20 d-none md:d-block">
            <div className="col-auto">
              <div className="d-inline-block">

                <a href="#" className="button -md -blue-1 bg-blue-1-05 text-blue-1">
                  View All Destinations <div className="icon-arrow-top-right ml-15"></div>
                </a>

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    
    {/* Hotels section */}
    <section className="layout-pt-md layout-pb-md">
      <div data-anim="slide-up delay-1" className="container is-in-view">
        <div className="row y-gap-10 justify-between items-end">
          <div className="col-auto">
            <div className="sectionTitle -md">
              <h2 className="sectionTitle__title">Hotels</h2>
            </div>
          </div>

          <div className="col-auto md:d-none">

            <a href="https://blessingsonthenet.com/hotels/page/1" className="button -md -blue-1 bg-blue-1-05 text-blue-1">
              View All Hotels <div className="icon-arrow-top-right ml-15"></div>
            </a>

          </div>
        </div>

        <div className="relative overflow-hidden pt-40 sm:pt-20 js-section-slider swiper-initialized swiper-horizontal swiper-backface-hidden swiper-pointer-events swiper-autoheight swiper-watch-progress is-in-view" data-gap="30" data-scrollbar data-slider-cols="xl-4 lg-3 md-2 sm-2 base-1" data-nav-prev="js-hotels-prev" data-pagination="js-hotels-pag" data-nav-next="js-hotels-next">
          <div className="swiper-wrapper">
            <Swiper
              spaceBetween={30}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              loop={true}
              navigation={true}
              modules={[Autoplay, Navigation]}
              className="mySwiper"
              breakpoints={{
                // When window width is <= 767px (typically mobile phones), show 2 slides
                767: {
                  slidesPerView: 2,
                },
                // Default: When window width is > 767px, show 4 slides
                // You can adjust this breakpoint based on your design requirements
                768: {
                  slidesPerView: 4,
                },
              }}
            >
            {hotelData.map((hpackage) => (
              <SwiperSlide>
                <div className="swiper-slide swiper-slide-visible swiper-slide-active" key={hpackage.id} 
                style={{marginRight: '30px'}}>
                  <a href={`/${domainName === 'shirdisaitemple.com' ? 'shirdi-hotel' : 'hotel'}/id/${hpackage.id}/${id}/${hpackage.name.toLowerCase().replace(/\s+/g, '-')}`} className="hotelsCard -type-1 ">
                    <div className="hotelsCard__image">
                      <div className="cardImage ratio ratio-1:1">
                        <div className="cardImage__content">
                        
                          <img src={`https://api.blessingsonthenet.com/uploads/hotels/${hpackage.thumb_new}`} alt={hpackage.name} />
                      
                        </div>
                      </div>

                    </div>

                    <div className="hotelsCard__content mt-10">
                      <h4 className="hotelsCard__title text-dark-1 text-18 lh-16 fw-500">
                        <span>{hpackage.name}</span>
                      </h4>
    
                      <p className="text-light-1 lh-14 text-14 mt-5">{hpackage.city ? hpackage.city : ''}{hpackage.state ? ', '+hpackage.state : ''}</p>
    
                      <div className="mt-5">
                        <div className="fw-500">
                          {!hpackage.price || hpackage.price === "0"
                            ? ""
                            : 'Starting from '}
                          <span className="text-blue-1">
                          {!hpackage.price || hpackage.price === "0"
                            ? "Contact us for Price"
                            : `₹${hpackage.price}/-`}
                          </span>
                        </div>
                      </div>
                    </div>
                  </a>

                </div>
              </SwiperSlide>
            ))}
            </Swiper>
          </div>

          <div className="row pt-20 d-none md:d-block">
            <div className="col-auto">
              <div className="d-inline-block">

                <a href="#" className="button -md -blue-1 bg-blue-1-05 text-blue-1">
                  View All Destinations <div className="icon-arrow-top-right ml-15"></div>
                </a>

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    {/* Articles Section */}
    <section class="layout-pt-lg layout-pb-md relative" id="secondSection">
      <div data-anim-wrap="" class="container animated">
        <div data-anim-child="slide-up delay-1" class="row y-gap-20 justify-center text-center is-in-view">
          <div class="col-auto">
            <div class="sectionTitle -md">
              <h2 class="sectionTitle__title">Articles</h2>
              {/*<p class=" sectionTitle__text mt-5 sm:mt-0">Here are list of nearby places that you might be intersted in</p>*/}
            </div>
          </div>
        </div>

        <div class="row x-gap-10 y-gap-10 pt-40 sm:pt-20">

          {articleListings.map((temple) => (
            <div key={temple.id} data-anim-child="slide-up delay-4" class="col-xl-4 col-lg-3 col-6 is-in-view">
              <a href={`/${domainName === 'shirdisaitemple.com' ? 'shirdi-article' : 'article'}/id/${temple.id}/temple/${id}/${temple.title.toLowerCase().replace(/\s+/g, '-')}`} class="citiesCard -type-5 d-flex items-center sm:flex-column sm:items-start px-20 py-20 sm:px-15 sm:py-20 bg-light-2 rounded-4">
                <div class="ml-10 sm:ml-0 sm:mt-10">
                  <h4 title={temple.title} class="text-16 fw-500"> {temple.title.length > 50 ? `${temple.title.substring(0, 50)}...` : temple.title}</h4>
                </div>
              </a>
            </div>
          ))}

        </div>
      </div>
    </section>

    <SubscribeRibbon />

    </>
  );
}

export default GroupProductListings;
// routes.js
import React from 'react';
import { Route, Routes } from 'react-router-dom'; // Update import statement
import Home from './components/Home';
import TourListings from './components/TourListings';
import HotelListings from './components/HotelListings';
import Hotel from './components/Hotel';
import Tariff from './components/Tariff';
import HotelContactInfo from './components/HotelContactInfo';
import Article from './components/Article';
import ProductListings from './components/ProductListings';
import Product from './components/Product';
import IndiaTemples from './components/IndiaTemples';
import GroupArticle from './components/GroupArticle';
import GroupTourListings from './components/GroupTourListings';
import GroupInnerArticle from './components/GroupInnerArticle';
import GroupProductListings from './components/GroupProductListings';
import GroupHotelListings from './components/GroupHotelListings';
import GroupHotel from './components/GroupHotel';
import IndiaTempleTourWithId from './components/IndiaTempleTourWithId';

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/page/:id" element={<Home />} />

      {/* For old website redirects */}
      <Route path="hotels//" element={<Home />} />
      <Route path="tour-package/" element={<Home />} />
      <Route path="accommodation-enquiry//" element={<Home />} />
      <Route path="accommodation-enquiry/" element={<Home />} />
      <Route path="accommodation-enquiry/id/:sampleId/:samplePlace/:samplePlaceId/:sampleTitle" element={<Home />} />
      
      {/* Specific to Shirdisaitemple */}
      <Route path="/shirdi-tour-package/:title" element={<TourListings />} />
      <Route path="/shirdi-tour-package/:title/page/:id" element={<TourListings />} />
      <Route path="/shirdi-hotels/:place/:categoryId/:title" element={<HotelListings />} />
      <Route path="/shirdi-hotels/:place/:categoryId/:title/page/:id" element={<HotelListings />} />
      <Route path="/shirdi-hotel/id/:id/:title" element={<Hotel />} />
      <Route path="/shirdi-hotel/tariff/:id/:title" element={<Tariff />} />
      <Route path="/shirdi-hotel/room-details/:title/:id/:roomType/:roomId" element={<Tariff />} />
      <Route path="/shirdi-hotel/room-details/:id/:title/:roomId" element={<Tariff />} />
      <Route path="/shirdi-hotel/contact-info/:id/:title" element={<HotelContactInfo />} />
      <Route path="/shirdi-hotel/contact-info/:id/:title/.https://shirdisaitemple.com/" element={<HotelContactInfo />} />
      <Route path="/shirdi-hotel/contact-info/:id/:templeId/:title" element={<HotelContactInfo />} />
      <Route path="/shirdi-article/id/:id/:title" element={<Article />} />
      <Route path="/shirdi-article/id/:id/.https://shirdisaitemple.com/" element={<Article />} />
      <Route path="/shirdi-tour-packages/id/:id/" element={<Article />} />

      {/* Specific to Indiatemple.net */}
      <Route path="/tour-package/:packageId/:templeId/" element={<IndiaTempleTourWithId />} />

      {/* All other Routes */}
      <Route path="/tour-package/:title" element={<TourListings />} />
      <Route path="/tour-package/:title/page/:id" element={<TourListings />} />
      <Route path="/hotels/:place/:categoryId/:title" element={<HotelListings />} />
      <Route path="/hotels/:place/:categoryId/:title/page/:id" element={<HotelListings />} />
      <Route path="/hotel/id/:id/:title" element={<Hotel />} />
      <Route path="/hotel/id/:id" element={<Hotel />} />
      <Route path="/hotel/tariff/:id/:title" element={<Tariff />} />
      <Route path="/hotel/tariff/:id/:placeId/:title" element={<Tariff />} />
      <Route path="/hotel/room-details/:title/:id/:roomType/:roomId" element={<Tariff />} />
      <Route path="/hotel/contact-info/:id/:title" element={<HotelContactInfo />} />
      <Route path="/hotel/contact-info/:id/:templeId/:title" element={<HotelContactInfo />} />
      <Route path="/article/id/:id/:title" element={<Article />} />
      <Route path="/article/id/:id/:title/:temple" element={<Article />} />
      <Route path="/show-idols" element={<ProductListings />} />
      <Route path="/product/:name/:id" element={<Product />} />
      <Route path="/tour-packages/id/:id/" element={<Article />} />
      <Route path="/tour-packages/:id/:templeId/" element={<GroupInnerArticle />} />
      <Route path="/indian-temple/" element={<IndiaTemples />} />
      <Route path="/indian-temples/" element={<IndiaTemples />} />
      <Route path="/temple/id/:id/:title" element={<GroupArticle />} />
      <Route path="/article/id/:id/temple/:templeId/:title" element={<GroupInnerArticle />} />
      <Route path="/article/id/:id/temple/:templeId/:title/:temple" element={<GroupInnerArticle />} />
      <Route path="/tour-packages/id/:id/:title" element={<GroupTourListings />} />
      <Route path="/tour-packages/id/:id/:title/page/:page" element={<GroupTourListings />} />
      <Route path="/show-idols/id/:id/:title" element={<GroupProductListings />} />
      <Route path="/hotels/id/:id/:place/:placeId/:title" element={<GroupHotelListings />} />
      <Route path="/hotels/id/:id/:place/:placeId/:title/page/:pageId" element={<GroupHotelListings />} />
      <Route path="/hotel/id/:id/:placeId/:title" element={<GroupHotel />} />

    </Routes>
  );
}

export default AppRoutes;
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getCleanedDomainName } from './urlFetch';

export function GroupHeader() {

    // Domain details Fetch
    const [domainName, setDomainName] = useState('');

    // Fetching the domain name
    useEffect(() => {
        setDomainName(getCleanedDomainName());
    }, []);

    console.log('Cleaned Domain Name:', domainName);

    // Get the current URL
    const currentUrl = window.location.href;

    // Use regular expressions to extract the ID from the URL
    const match = currentUrl.match(/\/(temple|tour-packages|article|show-idols|hotels|hotel)\/id\/(\d+)\/[^/]+/);
    //const match = currentUrl.match(/\/(temple|tour-packages|article)\/id\/(\d+)(?:\/[^/]+)?\/(\d+)\/[^/]+/);



    // Extracted ID will be in match[1]
    const id = match ? match[2] : null;

    console.log("Header ID: ", id);

    // Fetching Homam Categories
    const [otherTemples, setOtherTemples] = useState([]);

    let initialMicroData;

    if (currentUrl.includes('article')) {
      initialMicroData = {
        id: '',
        title: '',
        temple_id: '',
        microsite_id: '',
        microsite_name: '',
        microsite_location: '',
        microsite_city_name: '',
      };
    } else {
      initialMicroData = {
        id: '',
        name: '',
        domain: '',
        p1: '',
        p2: '',
        p3: '',
        place: '',
        location: '',
        city: '',
        analytics: '',
      };
    }
      
    const [microData, setMicroData] = useState(initialMicroData);

    let articleURL;

    useEffect(() => {

        const otherTemplesURL = `${process.env.REACT_APP_API_ENDPOINT}microsite_data/all?cat=group`;
        fetch(otherTemplesURL)
        .then(response => response.json())
        .then(data => setOtherTemples(data))
        .catch(error => console.log(error));

        if (currentUrl.includes('article')) {
          articleURL = `${process.env.REACT_APP_API_ENDPOINT}microsite_data?inner_article_id=${id}`;
        } else{
          articleURL = `${process.env.REACT_APP_API_ENDPOINT}microsite_data?article_id=${id}`;
        }
        console.log('articleURL:', articleURL);
        fetch(articleURL)
            .then(response => response.json())
            .then(data => {
            console.log('Header Data:', data[0]);
            setMicroData(data[0]); // Assuming data is an array with a single item
            })
            .catch(error => console.error('API Error:', error));
        
    }, [id]);
    console.log("otherTemples", otherTemples);

    let tourPackageUrlPart;
    let hotelUrlPart;

    if (currentUrl.includes('hotel/')) {
      tourPackageUrlPart = '';
    } else{
      if(id){
        if (currentUrl.includes('article')) {
          tourPackageUrlPart = microData.temple_id +'/'+ microData.microsite_name.toLowerCase().replace(/\s+/g, '-');
          hotelUrlPart = microData.temple_id +'/'+ microData.microsite_city_name +'/'+ microData.microsite_location + '/' + microData.microsite_name.toLowerCase().replace(/\s+/g, '-');
        } else {
          tourPackageUrlPart = microData.id +'/'+ microData.name.toLowerCase().replace(/\s+/g, '-');
          hotelUrlPart = microData.id +'/'+ microData.city +'/'+ microData.location +'/'+ microData.name.toLowerCase().replace(/\s+/g, '-');
        }
      }
    }


    const [siteData, setSiteData] = useState({
      id: '',
      analytics: '',
    });
    const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}microsite_data/${domainName}?cat=group`;
  
    useEffect(() => {
      if(domainName){
        console.log('API URL:', apiUrl);
  
        fetch(apiUrl)
          .then(response => response.json())
          .then(data => {
            console.log('API Response:', data);
            setSiteData(data[0]); // Assuming data is an array with a single item
          })
          .catch(error => console.error('API Error:', error));
      }
    }, [domainName, apiUrl]);

    const googleTagManagerScript = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', '${siteData.analytics}');
  `;
    

  const [isMenuActive, setMenuActive] = useState(false);

  const handleMenuButtonClick = () => {
    setMenuActive(!isMenuActive);
  };

  return (
    <>
    {siteData.banner ? (
      <center>
        <a href="https://wa.me/919892002006">
          <img class="top_banner py-10" src={`https://api.blessingsonthenet.com/uploads/microsite/banners/${siteData.banner}`} />
        </a>
      </center>
      ) : ''}
    <header data-add-bg="bg-dark-1" class="header bg-green js-header" data-x="header" data-x-toggle="is-menu-opened">

        {/* Google tag (gtag.js) */}
        <script async src={`https://www.googletagmanager.com/gtag/js?id=${siteData.analytics}`}></script>
        <script dangerouslySetInnerHTML={{ __html: googleTagManagerScript }} />

      <div data-anim="fade" class="header__container px-30 sm:px-20">
        <div class="row justify-between items-center">
          
          <div class="col-auto">
            <div class="d-flex items-center">
              <a href="/" class="header-logo mr-20 text-22" data-x="header-logo" data-x-toggle="is-logo-dark">
                {/*
                <img src="https://blessingsonthenet.com/img/Nirvana-Enterprises-Logo.png" alt="logo icon" />
                <img src="https://blessingsonthenet.com/img/Nirvana-Enterprises-Logo.png" alt="logo icon" />
                */}
                {domainName && domainName.charAt(0).toUpperCase() + domainName.slice(1)}
              </a>

              <div className={`header-menu ${isMenuActive ? 'is-menu-active' : ''}`} data-x="mobile-menu" data-x-toggle={isMenuActive ? 'is-menu-active' : ''} style={{ position: 'absolute', right: '150px' }}>

                <div class="header-menu__content">
                  <div class="mobile-bg js-mobile-bg"></div>

                  <div class="menu js-navList">
                    <ul class="menu__nav text-white -is-active">
                        
                        {id && tourPackageUrlPart !== '' && hotelUrlPart ? 
                        (<>
                        <li>
                            <a data-barba href="/">
                            <span class="mr-10">Home</span>
                            </a>

                        </li>
                        <li>
                            <a href={`/tour-packages/id/${tourPackageUrlPart ? tourPackageUrlPart : ''}`}>
                            Pilgrimage Tour Packages
                            </a>
                        </li>

                        <li>
                            <a href={`/show-idols/id/${tourPackageUrlPart}`}>
                            Online Shopping
                            </a>
                        </li>

                        <li>
                            <a href={`/hotels/id/${hotelUrlPart}`}>
                            Hotels
                            </a>
                        </li></>) : ''
                        }

                        <li class="menu-item-has-children">
                            <a data-barba href="#">
                            <span class="mr-10">Other Temples</span>
                            <i class="icon icon-chevron-sm-down"></i>
                            </a>

                            <ul class="subnav menus_custom">
                            <li class="subnav__backBtn js-nav-list-back">
                                <a href="#"><i class="icon icon-chevron-sm-down"></i> Blog</a>
                            </li>
                            
                            {otherTemples.map(item => (
                                <li key={item.id}>  
                                    <a target="_blank" href={`https://${item.domain}`}>
                                        {item.domain}
                                    </a>
                                </li>
                            ))}

                            </ul>

                        </li>     

                        {/* Mobile Only Temples List */}
                        {otherTemples.map(item => (
                            <li key={item.id} class="mobile_only_item">  
                                <a target="_blank" href={`https://${item.domain}`}>
                                    {item.domain}
                                </a>
                            </li>
                        ))}            

                    </ul>
                  </div>

                  <div class="mobile-footer px-20 py-20 border-top-light js-mobile-footer">
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div class="col-auto">
            <div class="d-flex items-center">
              <div class="d-none xl:d-flex x-gap-20 items-center pl-30 text-white" data-x="header-mobile-icons" data-x-toggle="text-white">
                <div>
                    <button class="d-flex items-center icon-menu text-inherit text-20" data-x-click="html, header, header-logo, header-mobile-icons, mobile-menu" onClick={handleMenuButtonClick}></button>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    </header>
    </>
  );
}
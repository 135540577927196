import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { SubscribeRibbon} from './SubscribeRibbon';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { getCleanedDomainName } from './urlFetch';
import {Helmet} from "react-helmet";

function GroupHotelListings() {

    // Domain details Fetch
    const [domainName, setDomainName] = useState('');

    // Fetching the domain name
    useEffect(() => {
        setDomainName(getCleanedDomainName());
    }, []);

    console.log('Cleaned Domain Name:', domainName);

    const { id, title, keyword, categoryId, state, pageId } = useParams();

    const [microData, setMicroData] = useState({
        id: '',
        name: '',
        domain: '',
        p1: '',
        p2: '',
        p3: '',
        place: '',
    });
    useEffect(() => {
        if(id){
            const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}microsite_data?article_id=${id}`;
          console.log('API URL:', apiUrl);
    
          fetch(apiUrl)
            .then(response => response.json())
            .then(data => {
              console.log('API Response:', data);
              setMicroData(data[0]); // Assuming data is an array with a single item
            })
            .catch(error => console.error('API Error:', error));
        }
      }, [id]);

    //console.log('Name:', microData.name);
    //console.log('Domain:', microData.domain);
    console.log('P1:', microData.p1);
    console.log('P2:', microData.p2);
    console.log('P3:', microData.p3);


    const [swiperData, setSwiperData] = useState([]);
    
    useEffect(() => {
        if(id){
          const microDataUrl = `${process.env.REACT_APP_API_ENDPOINT}microsite_data?article_id=${id}`;
          console.log('microDataUrl:', microDataUrl);
          // Replace with your API URL
          fetch(microDataUrl)
            .then((response) => response.json())
            .then((data) => setSwiperData(data))
            .catch((error) => console.error('Error fetching data:', error));
        }
      }, [id]);


    

    const location = useLocation();
    const navigate = useNavigate();
    
    const [holidayListings, setHolidayListings] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [priceFilter, setPriceFilter] = useState("");
    const [durationFilter, setDurationFilter] = useState("");
    const [showWithoutPrice, setShowWithoutPrice] = useState(true);
    const [totalCount, setTotalCount] = useState(0);

    console.log("title: ", title);
    
    useEffect(() => {
      let page = pageId ? parseInt(pageId) : currentPage; // Use the value from the URL if present
      setCurrentPage(page);
    
      const searchParams = new URLSearchParams(location.search);
    
      if (keyword) {
        searchParams.set('keyword', keyword);
      }
      if (priceFilter) {
        searchParams.set('price', priceFilter);
      }
      if (durationFilter) {
        searchParams.set('duration', durationFilter);
      }
      if (showWithoutPrice) {
        searchParams.set('shp', '1');
      } else {
        searchParams.set('shp', '0');
      }

      const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}hotel_listings?city=${microData.place}&page=${page}`;
      console.log("apiUrl: ", apiUrl);      
    
      fetch(apiUrl)
        .then((response) => response.json())
        .then((data) => {
            setHolidayListings(data);
            setTotalCount(data.length > 0 ? data[0].totalCount : 0);
            const totalPages = Math.ceil(data[0].totalCount / 10);
            setTotalPages(totalPages);
        })
        .catch((error) => console.log(error));
    }, [id, currentPage, keyword, priceFilter, durationFilter, showWithoutPrice, categoryId, state, totalCount, microData]);

    const handlePageClick = (page) => {
        setCurrentPage(page);
      
        const path = location.pathname;
        const currentQueryParams = new URLSearchParams(location.search);
        currentQueryParams.delete('page');
      
        let newPath = path;
      
        if (path.includes('/page/')) {
          newPath = path.replace(/\/page\/\d+/, `/page/${page}`);
        } else {
          newPath = `${path}/page/${page}`;
        }
      
        const newURL = `${newPath}${currentQueryParams.toString() ? `?${currentQueryParams.toString()}` : ''}`;
        navigate(newURL);

         // Scroll to the top of the page
         window.scrollTo({
            top: 600,
            behavior: 'smooth',
        });
      };            
      

const handlePriceFilter = (price) => {
  setPriceFilter(price);
  appendFilterToURL('price', price);
};

const handleDurationFilter = (duration) => {
  setDurationFilter(duration);
  appendFilterToURL('duration', duration);
};

const handleShowWithoutPrice = (event) => {
  setShowWithoutPrice(event.target.checked);
  appendFilterToURL('shp', event.target.checked ? '1' : '0');
};

const appendFilterToURL = (filterType, value) => {
  const searchParams = new URLSearchParams(location.search);

  if (filterType === 'page') {
    searchParams.set(filterType, value);
  } else {
    if (searchParams.has(filterType)) {
      searchParams.delete(filterType);
    }

    searchParams.set(filterType, value);
  }

  const newURL = `${location.pathname}?${searchParams.toString()}`;
  navigate(newURL);
};

function updateURLParameter(url, param, value) {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete(param);
    searchParams.append(param, value);
    return `${url.split('?')[0]}?${searchParams.toString()}`;
  }  

// Fetching Holiday Categories
const [holidayCategories, setHolidayCategories] = useState([]);

useEffect(() => {
    const interestsUrl = `${process.env.REACT_APP_API_ENDPOINT}interest_types`;
    fetch(interestsUrl)
        .then(response => response.json())
        .then(data => setHolidayCategories(data))
        .catch(error => console.log(error));
}, []);


// Fetching Holiday Categories
const [statesFetch, setStatesFetch] = useState([]);

useEffect(() => {
    const statesUrl = `${process.env.REACT_APP_API_ENDPOINT}cities?states=all`;
    fetch(statesUrl)
        .then(response => response.json())
        .then(data => setStatesFetch(data))
        .catch(error => console.log(error));
}, []);

const currentUrl = window.location.href;

    return ( 
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{microData.place} Hotels</title>
            <link rel="canonical" href={currentUrl} />
        </Helmet>
        <div>

            {/* Slider section */}
            <section className="z-5">
                <div className="swiper-wrapper">
                    <Swiper
                    spaceBetween={30}
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: false,
                    }}
                    slidesPerView={1}
                    loop={true}
                    modules={[Autoplay]}
                    className="mySwiper"
                    >
                    {swiperData.map((item) => (
                        <SwiperSlide key={item.id}>
                        <img style={{width:'100%'}} src={`https://blessingsonthenet.com/img/uploads/${item.image}`} alt={`Slide ${item.id}`} />
                        </SwiperSlide>
                    ))}
                    </Swiper>
                </div>
            </section>

            <section class="layout-pt-md layout-pb-lg">
                <div class="container">

                    <div class="row items-center justify-between">
                        <div class="col-auto">
                            <span class="fw-500 text-25">{microData.place} Hotels</span>
                        </div>
                    </div>

                    <div class="col-12 row">
                        {holidayListings.map(list => (
                            <div class="col-6 mt-30">
                                <div class="row y-gap-30">
                                    <div class="col-12">
                                        <div class="border-top-light pt-30">
                                            <div class="row x-gap-20 y-gap-20">
                                                <div class="col-4">
                                                    <div class="cardImage ratio ratio-1:1 w-250 md:w-1/1 rounded-4">
                                                        <div class="cardImage__content">
                                                            <a href={domainName === 'shirdisaitemple.com' ? `/shirdi-hotel/id/${list.id}/${id}/${list.name.toLowerCase().replace(/\s+/g, '-')}` : `/hotel/id/${list.id}/${id}/${list.name.toLowerCase().replace(/\s+/g, '-')}`}>
                                                                <img className="rounded-4 col-12" src={list.thumb_new ? `https://api.blessingsonthenet.com/uploads/hotels/${list.thumb_new}` : `https://www.blessingsonthenet.com/img/uploads/hotels/thumbs/${list.thumbnail_image}`} alt="image" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-8">
                                                    <a href={domainName === 'shirdisaitemple.com' ? `/shirdi-hotel/id/${list.id}/${id}/${list.name.toLowerCase().replace(/\s+/g, '-')}` : `/hotel/id/${list.id}/${id}/${list.name.toLowerCase().replace(/\s+/g, '-')}`}>
                                                        <h3 class="text-18 lh-16 fw-500">{list.name}<br class="lg:d-none" /></h3>
                                                    </a>
                                                    <div class="text-14 lh-15 mt-10">
                                                        <div class="fw-500" dangerouslySetInnerHTML={{ __html: list.short_description.replace(/<[^>]+>/g, '').replace(/&nbsp;/g, ' ').slice(0, 115) + "..." }} />
                                                    </div>
                                                    <div class="row col-12">
                                                        <div class="col-6">
                                                        {list.price && list.price !== "0" ? (
                                                            <div>
                                                                <div className="text-14 text-light-1 mt-10 md:mt-20">Starts from</div>
                                                                <div className="text-22 lh-12 fw-600">₹{list.price}/-</div>
                                                            </div>
                                                            ) : (
                                                            <div className="text-14 text-light-1 mt-20 md:mt-20 pt-5">Contact us for Price</div>
                                                            )}
                                                        </div>
                                                        <div class="col-6">
                                                            <a href={domainName === 'shirdisaitemple.com' ? `/shirdi-hotel/id/${list.id}/${list.name.toLowerCase().replace(/\s+/g, '-')}` : `/hotel/id/${list.id}/${list.name.toLowerCase().replace(/\s+/g, '-')}`} style={{paddingRight:'10px', paddingLeft:'10px'}} class="button -md -dark-1 bg-blue-1 text-white mt-15">
                                                                View Details
                                                                <div class="icon-arrow-top-right ml-15"></div>
                                                            </a>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="text-14 text-green-2 lh-15 mt-15 border-top-light">
                                                                <div class="mt-10">Call us <b>0845 2040 404</b> to customize this hotel.</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>


                    <div class="row y-gap-30">

                        <div class="col-12">

                        <div class="border-top-light mt-20 pt-30">

                            <div class="pt-10">
                                <div class="row justify-between">
                                    <div class="col-auto">

                                    </div>

                                    <div className="col-auto">
                                        <div className="row x-gap-20 y-gap-20 items-center pagination">
                                            {currentPage !== 1 && (
                                            <div
                                                className="col-auto pagination_numbers"
                                                onClick={() => handlePageClick(1)}
                                            >
                                                <div className="size-40 flex-center rounded-full">1</div>
                                            </div>
                                            )}
                                            {currentPage > 3 && (
                                            <div className="col-auto">
                                                <div className="size-40 flex-center rounded-full">...</div>
                                            </div>
                                            )}
                                            {Array.from({ length: totalPages }, (_, index) => {
                                            const page = index + 1;
                                            if (page === totalPages) return null; // Skip the last page number
                                            if (
                                                page < currentPage - 1 ||
                                                page > currentPage + 1 ||
                                                (currentPage === 2 && page === 1) // Skip displaying 1 when on the second page
                                            )
                                                return null;
                                            return (
                                                <div
                                                key={index}
                                                className={`col-auto pagination_numbers ${
                                                    page === currentPage ? "bg-dark-1 text-white" : ""
                                                }`}
                                                onClick={() => handlePageClick(page)}
                                                >
                                                <div className="size-40 flex-center rounded-full">{page}</div>
                                                </div>
                                            );
                                            })}
                                            {currentPage < totalPages - 2 && (
                                            <div className="col-auto">
                                                <div className="size-40 flex-center rounded-full">...</div>
                                            </div>
                                            )}
                                            {currentPage !== totalPages && (
                                            <div
                                                className="col-auto pagination_numbers"
                                                onClick={() => handlePageClick(totalPages)}
                                            >
                                                <div className="size-40 flex-center rounded-full">{totalPages}</div>
                                            </div>
                                            )}
                                        </div>
                                    </div>


                                    <div class="col-auto">
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </section>
            <SubscribeRibbon />
        </div>
    </>
    );
}

export default GroupHotelListings;
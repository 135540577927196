import React, { useEffect, useState } from 'react';
//import './assets/css/body.css'; // Import CSS from the theme
import { SubscribeRibbon} from './SubscribeRibbon';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { getCleanedDomainName } from './urlFetch';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import {Helmet} from "react-helmet";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

export function GroupHome() {

    const location = useLocation();
    const navigate = useNavigate();

    // Domain details Fetch
    const [domainName, setDomainName] = useState('');

    // Fetching the domain name
    useEffect(() => {
        setDomainName(getCleanedDomainName());
    }, []);

    console.log('Cleaned Domain Name:', domainName);

    const [domainID, setDomainID] = useState([]);

    useEffect(() => {
        if(domainName){
        // Fetch microsite data from the API
        const groupCheck = `${process.env.REACT_APP_API_ENDPOINT}microsite_id_for_domain/${domainName}`;
        console.log("groupCheck: ", groupCheck);
        fetch(groupCheck)
            .then(response => response.json())
            .then(data => setDomainID(data[0]?.id || null))
            .catch(error => console.error('Error fetching microsite data:', error));
        }
    }, [domainName]);

    console.log("domainID: ", domainID);

    const { id, keyword, place, materialId } = useParams();
    const [productListings, setProductListings] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [priceFilter, setPriceFilter] = useState("");
    const [hclassFilter, setHclassFilter] = useState("");
    const [showWithoutPrice, setShowWithoutPrice] = useState(true);
    const [totalCount, setTotalCount] = useState(0);

    useEffect(() => {
        let page = id ? parseInt(id) : currentPage; // Use the value from the URL if present
        setCurrentPage(page);
      
        const searchParams = new URLSearchParams(location.search);
      
        if (keyword) {
          searchParams.set('keyword', keyword);
        }
        if (priceFilter) {
          searchParams.set('price', priceFilter);
        }
        if (hclassFilter) {
          searchParams.set('hclass', hclassFilter);
        }
        if (showWithoutPrice) {
          searchParams.set('shp', '1');
        } else {
          searchParams.set('shp', '0');
        }
  
        const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}microsite_group_temples?id=${domainID}&page=${page}&${searchParams.toString()}${place ? `&place=${place}` : ''}${materialId ? `&material=${materialId}` : ''}${domainID ? `&group_id=${domainID}` : ''}`;
        console.log("apiUrl: ", apiUrl);      
      
        fetch(apiUrl)
          .then((response) => response.json())
          .then((data) => {
              setProductListings(data);
              setTotalCount(data.length > 0 ? data[0].totalCount : 0);
              const totalPages = Math.ceil(data[0].totalCount / 20);
              setTotalPages(totalPages);
          })
          .catch((error) => console.log(error));
      }, [id, currentPage, keyword, priceFilter, hclassFilter, showWithoutPrice, place, materialId, totalCount, domainID]);

      console.log("location: ", location);

    const handlePageClick = (page) => {
        setCurrentPage(page);

        navigate(`/page/${page}`);

        // Scroll to the top of the page
        window.scrollTo({
            top: 200,
            behavior: 'smooth',
        });
      };    
      

      const [categoryFetch, setCategoryFetch] = useState([]);
      const category = productListings.length > 0 ? productListings[0].category : null;

      useEffect(() => {
        if (category) {
          const categoryFetchAPIURL = `${process.env.REACT_APP_API_ENDPOINT}categories?category_id=${category}`;
          console.log("categoryFetchAPIURL: ", categoryFetchAPIURL);
          fetch(categoryFetchAPIURL)
            .then(response => response.json())
            .then(data => {
              if (data.length > 0) {
                setCategoryFetch(data);
              }
            })
            .catch(error => console.error(error));
        }
      }, [category]);
      
    const handlePriceFilter = (price) => {
    setPriceFilter(price);
    appendFilterToURL('price', price);
    };

    const handleHclassFilter = (hclass) => {
    setHclassFilter(hclass);
    appendFilterToURL('hclass', hclass);
    };

    const handleShowWithoutPrice = (event) => {
    setShowWithoutPrice(event.target.checked);
    appendFilterToURL('shp', event.target.checked ? '1' : '0');
    };

    const appendFilterToURL = (filterType, value) => {
    const searchParams = new URLSearchParams(location.search);

    if (filterType === 'page') {
        searchParams.set(filterType, value);
    } else {
        if (searchParams.has(filterType)) {
        searchParams.delete(filterType);
        }

        searchParams.set(filterType, value);
    }

    const newURL = `${location.pathname}?${searchParams.toString()}`;
    navigate(newURL);
    };

    function updateURLParameter(url, param, value) {
        const searchParams = new URLSearchParams(location.search);
        searchParams.delete(param);
        searchParams.append(param, value);
        return `${url.split('?')[0]}?${searchParams.toString()}`;
    }  

    // Fetching Product Categories
    const [productCategories, setProductCategories] = useState([]);

    useEffect(() => {
        const categoryUrl = `${process.env.REACT_APP_API_ENDPOINT}shopping_categories`;
        fetch(categoryUrl)
            .then(response => response.json())
            .then(data => setProductCategories(data))
            .catch(error => console.log(error));
    }, []);

    // Fetching Places
    const [placesFetch, setPlacesFetch] = useState([]);

    useEffect(() => {
        const placeURL = `${process.env.REACT_APP_API_ENDPOINT}microsite_places_fetch/${domainID}?cat=place`;
        console.log("placeURL: ", placeURL);
        fetch(placeURL)
            .then(response => response.json())
            .then(data => setPlacesFetch(data))
            .catch(error => console.log(error));
    }, [domainID]);


    // Fetching Product Categories
    const [templeData, setTempleData] = useState([]);

    useEffect(() => {
        if(domainName){
            const templeDataURL = `${process.env.REACT_APP_API_ENDPOINT}microsite_data/${domainName}?cat=group`;
            console.log("templeDataURL", templeDataURL);
            fetch(templeDataURL)
                .then(response => response.json())
                .then(data => setTempleData(data))
                .catch(error => console.log(error));
        }
    }, [domainName]);

    function truncateAndStripHtml(input, maxLength) {
        // Remove HTML tags
        const strippedContent = input.replace(/<[^>]*>/g, '');
      
        // Truncate to the first maxLength characters
        const truncatedContent = strippedContent.substring(0, maxLength);
      
        return truncatedContent;
    }

    const [displayCount, setDisplayCount] = useState(30);

    const handleShowMore = () => {
      setDisplayCount(displayCount + 30);
    };

    const currentUrl = window.location.href;
  
  return (
    <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{templeData.length > 0 ? templeData[0].name : ''}</title>
            <link rel="canonical" href={currentUrl} />
        </Helmet>
        <section class="section-bg layout-pt-lg pb-20">
            <div class="section-bg__item col-12">
                <img src="/assets/img/pages/become-expert/temple.png" alt="image" />
            </div>

            <div class="container">
                <div class="row justify-center text-center">
                    <div class="col-10">
                        <h1 class="text-40 md:text-25 fw-600 text-white">
                        {templeData.length > 0 ? templeData[0].name : ''}
                        </h1>

                        <div class="description_box" style={{overflowY:'scroll', maxHeight:'300px'}} dangerouslySetInnerHTML={{ __html: `<div style={{lineHeight:'normal'}} class="banner_description">${templeData.length > 0 ? templeData[0].description : ''}...</div>` }}></div>
                    </div>
                </div>
            </div>
        </section>
        
        <section class="layout-pt-md layout-pb-lg">
            <div class="container">
            <div class="row y-gap-30">
                <div class="col-xl-3 col-lg-4 lg:d-none">
                <aside class="sidebar y-gap-40">

                    <div class="sidebar__item -no-border">
                    <h5 class="text-18 fw-500 mb-10">Filter by City</h5>
                    <div class="sidebar-checkbox">
                        <table class="table-4 w-1/1 blessings_categories">
                            <tbody>
                            {placesFetch.slice(0, displayCount).map((item, index) => (
                                <tr key={index}>
                                <td>
                                    <a href={`/?place=${item.place}`}>
                                    <img src={`/assets/img/general/categories-arrow.png`} alt={`Arrow for ${item.place}`} />
                                    {item.place}
                                    </a>
                                </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>

                        {displayCount < placesFetch.length && (
                            <button class="text-18 fw-500 text-blue-1 mt-5 ml-30" onClick={handleShowMore}>Show More</button>
                        )}
                    </div>
                    </div>

                    {/*
                    <div class="sidebar__item">
                    <h5 class="text-18 fw-500 mb-10">Product Categories</h5>
                    <div class="sidebar-checkbox">
                        <table class="table-4 w-1/1 blessings_categories">
                            <tbody>
                            {productCategories.map(item => (
                            <tr key={item.id}>
                                <td>    
                                <a href={`/products/type/${item.category_name.toLowerCase().replace(/\s+/g, '-')}/${item.id}`}>
                                    <img src={`/assets/img/general/categories-arrow.png`} />
                                    {item.category_name}
                                </a>
                                </td>
                            </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                    </div>

                    <div class="sidebar__item">
                    <h5 class="text-18 fw-500 mb-10">Filter by Material</h5>
                    <div class="sidebar-checkbox">
                        <table class="table-4 w-1/1 blessings_categories">
                            <tbody>
                            {materialsFetch.map(item => (
                            <tr key={item.id}>
                                <td>    
                                <a href={`/products/material/${item.material_name.toLowerCase().replace(/\s+/g, '-')}/${item.id}`}>
                                    <img src={`/assets/img/general/categories-arrow.png`} />
                                    {item.material_name}
                                </a>
                                </td>
                            </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                    </div>
                    */}
                   

                </aside>
                </div>

                <div class="col-xl-9 col-lg-8">
                    <div class="row y-gap-10 items-center justify-between">
                        <div class="col-auto">
                        <div class="text-18"><span class="fw-500">Found <span style={{color:'red'}}>{totalCount}</span> Results</span></div>
                        </div>
                    </div>

                <div class="filterPopup bg-white" data-x="filterPopup" data-x-toggle="-is-active">
                    <aside class="sidebar -mobile-filter">
                    <div data-x-click="filterPopup" class="-icon-close">
                        <i class="icon-close"></i>
                    </div>

                    <div class="sidebar__item">
                        <div class="flex-center ratio ratio-15:9 js-lazy" data-bg="img/general/map.png">
                        <button class="button py-15 px-24 -blue-1 bg-white text-dark-1 absolute" data-x-click="mapFilter">
                            <i class="icon-destination text-22 mr-10"></i>
                            Show on map
                        </button>
                        </div>
                    </div>

                    <div class="sidebar__item">
                        <h5 class="text-18 fw-500 mb-10">Search by property name</h5>
                        <div class="single-field relative d-flex items-center py-10">
                        <input class="pl-50 border-light text-dark-1 h-50 rounded-8" type="email" placeholder="e.g. Best Western" />
                        <button class="absolute d-flex items-center h-full">
                            <i class="icon-search text-20 px-15 text-dark-1"></i>
                        </button>
                        </div>
                    </div>

                    <div class="sidebar__item">
                        <h5 class="text-18 fw-500 mb-10">Deals</h5>
                        <div class="sidebar-checkbox">

                        <div class="row items-center justify-between">
                            <div class="col-auto">
                            <div class="d-flex items-center">
                                <div class="form-checkbox">
                                <input type="checkbox" />
                                <div class="form-checkbox__mark">
                                    <div class="form-checkbox__icon icon-check"></div>
                                </div>
                                </div>
                                <div class="text-15 ml-10">Free cancellation</div>
                            </div>
                            </div>
                        </div>

                        <div class="row items-center justify-between">
                            <div class="col-auto">
                            <div class="d-flex items-center">
                                <div class="form-checkbox">
                                <input type="checkbox" />
                                <div class="form-checkbox__mark">
                                    <div class="form-checkbox__icon icon-check"></div>
                                </div>
                                </div>
                                <div class="text-15 ml-10">Reserve now, pay at stay </div>
                            </div>
                            </div>
                        </div>

                        <div class="row items-center justify-between">
                            <div class="col-auto">
                            <div class="d-flex items-center">
                                <div class="form-checkbox">
                                <input type="checkbox" />
                                <div class="form-checkbox__mark">
                                    <div class="form-checkbox__icon icon-check"></div>
                                </div>
                                </div>
                                <div class="text-15 ml-10">Properties with special offers</div>
                            </div>
                            </div>
                        </div>

                        </div>
                    </div>

                    <div class="sidebar__item">
                        <h5 class="text-18 fw-500 mb-10">Popular Filters</h5>
                        <div class="sidebar-checkbox">

                        <div class="row items-center justify-between">
                            <div class="col-auto">
                            <div class="d-flex items-center">
                                <div class="form-checkbox">
                                <input type="checkbox" />
                                <div class="form-checkbox__mark">
                                    <div class="form-checkbox__icon icon-check"></div>
                                </div>
                                </div>
                                <div class="text-15 ml-10">Breakfast Included</div>
                            </div>
                            </div>

                            <div class="col-auto">
                            <div class="text-15 text-light-1">92</div>
                            </div>
                        </div>

                        <div class="row items-center justify-between">
                            <div class="col-auto">
                            <div class="d-flex items-center">
                                <div class="form-checkbox">
                                <input type="checkbox" />
                                <div class="form-checkbox__mark">
                                    <div class="form-checkbox__icon icon-check"></div>
                                </div>
                                </div>
                                <div class="text-15 ml-10">Romantic</div>
                            </div>
                            </div>

                            <div class="col-auto">
                            <div class="text-15 text-light-1">45</div>
                            </div>
                        </div>

                        <div class="row items-center justify-between">
                            <div class="col-auto">
                            <div class="d-flex items-center">
                                <div class="form-checkbox">
                                <input type="checkbox" />
                                <div class="form-checkbox__mark">
                                    <div class="form-checkbox__icon icon-check"></div>
                                </div>
                                </div>
                                <div class="text-15 ml-10">Airport Transfer</div>
                            </div>
                            </div>

                            <div class="col-auto">
                            <div class="text-15 text-light-1">21</div>
                            </div>
                        </div>

                        <div class="row items-center justify-between">
                            <div class="col-auto">
                            <div class="d-flex items-center">
                                <div class="form-checkbox">
                                <input type="checkbox" />
                                <div class="form-checkbox__mark">
                                    <div class="form-checkbox__icon icon-check"></div>
                                </div>
                                </div>
                                <div class="text-15 ml-10">WiFi Included </div>
                            </div>
                            </div>

                            <div class="col-auto">
                            <div class="text-15 text-light-1">78</div>
                            </div>
                        </div>

                        <div class="row items-center justify-between">
                            <div class="col-auto">
                            <div class="d-flex items-center">
                                <div class="form-checkbox">
                                <input type="checkbox" />
                                <div class="form-checkbox__mark">
                                    <div class="form-checkbox__icon icon-check"></div>
                                </div>
                                </div>
                                <div class="text-15 ml-10">5 Star</div>
                            </div>
                            </div>

                            <div class="col-auto">
                            <div class="text-15 text-light-1">679</div>
                            </div>
                        </div>

                        </div>
                    </div>

                    <div class="sidebar__item pb-30">
                        <h5 class="text-18 fw-500 mb-10">Nightly Price</h5>
                        <div class="row x-gap-10 y-gap-30">
                        <div class="col-12">
                            <div class="js-price-rangeSlider">
                            <div class="text-14 fw-500"></div>

                            <div class="d-flex justify-between mb-20">
                                <div class="text-15 text-dark-1">
                                <span class="js-lower"></span>
                                -
                                <span class="js-upper"></span>
                                </div>
                            </div>

                            <div class="px-5">
                                <div class="js-slider"></div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>

                    <div class="sidebar__item">
                        <h5 class="text-18 fw-500 mb-10">Amenities</h5>
                        <div class="sidebar-checkbox">

                        <div class="row items-center justify-between">
                            <div class="col-auto">

                            <div class="d-flex items-center">
                                <div class="form-checkbox ">
                                <input type="checkbox" name="name" />
                                <div class="form-checkbox__mark">
                                    <div class="form-checkbox__icon icon-check"></div>
                                </div>
                                </div>

                                <div class="text-15 ml-10">Breakfast Included</div>

                            </div>

                            </div>

                            <div class="col-auto">
                            <div class="text-15 text-light-1">92</div>
                            </div>
                        </div>

                        <div class="row items-center justify-between">
                            <div class="col-auto">

                            <div class="d-flex items-center">
                                <div class="form-checkbox ">
                                <input type="checkbox" name="name" />
                                <div class="form-checkbox__mark">
                                    <div class="form-checkbox__icon icon-check"></div>
                                </div>
                                </div>

                                <div class="text-15 ml-10">WiFi Included </div>

                            </div>

                            </div>

                            <div class="col-auto">
                            <div class="text-15 text-light-1">45</div>
                            </div>
                        </div>

                        <div class="row items-center justify-between">
                            <div class="col-auto">

                            <div class="d-flex items-center">
                                <div class="form-checkbox ">
                                <input type="checkbox" name="name" />
                                <div class="form-checkbox__mark">
                                    <div class="form-checkbox__icon icon-check"></div>
                                </div>
                                </div>

                                <div class="text-15 ml-10">Pool</div>

                            </div>

                            </div>

                            <div class="col-auto">
                            <div class="text-15 text-light-1">21</div>
                            </div>
                        </div>

                        <div class="row items-center justify-between">
                            <div class="col-auto">

                            <div class="d-flex items-center">
                                <div class="form-checkbox ">
                                <input type="checkbox" name="name" />
                                <div class="form-checkbox__mark">
                                    <div class="form-checkbox__icon icon-check"></div>
                                </div>
                                </div>

                                <div class="text-15 ml-10">Restaurant </div>

                            </div>

                            </div>

                            <div class="col-auto">
                            <div class="text-15 text-light-1">78</div>
                            </div>
                        </div>

                        <div class="row items-center justify-between">
                            <div class="col-auto">

                            <div class="d-flex items-center">
                                <div class="form-checkbox ">
                                <input type="checkbox" name="name" />
                                <div class="form-checkbox__mark">
                                    <div class="form-checkbox__icon icon-check"></div>
                                </div>
                                </div>

                                <div class="text-15 ml-10">Air conditioning </div>

                            </div>

                            </div>

                            <div class="col-auto">
                            <div class="text-15 text-light-1">679</div>
                            </div>
                        </div>

                        </div>
                    </div>

                    <div class="sidebar__item">
                        <h5 class="text-18 fw-500 mb-10">Star Rating</h5>
                        <div class="row y-gap-10 x-gap-10 pt-10">

                        <div class="col-auto">
                            <a href="#" class="button -blue-1 bg-blue-1-05 text-blue-1 py-5 px-20 rounded-100">1</a>
                        </div>

                        <div class="col-auto">
                            <a href="#" class="button -blue-1 bg-blue-1-05 text-blue-1 py-5 px-20 rounded-100">2</a>
                        </div>

                        <div class="col-auto">
                            <a href="#" class="button -blue-1 bg-blue-1-05 text-blue-1 py-5 px-20 rounded-100">3</a>
                        </div>

                        <div class="col-auto">
                            <a href="#" class="button -blue-1 bg-blue-1-05 text-blue-1 py-5 px-20 rounded-100">4</a>
                        </div>

                        <div class="col-auto">
                            <a href="#" class="button -blue-1 bg-blue-1-05 text-blue-1 py-5 px-20 rounded-100">5</a>
                        </div>

                        </div>
                    </div>

                    <div class="sidebar__item">
                        <h5 class="text-18 fw-500 mb-10">Guest Rating</h5>
                        <div class="sidebar-checkbox">

                        <div class="row items-center justify-between">
                            <div class="col-auto">

                            <div class="form-radio d-flex items-center ">
                                <div class="radio">
                                <input type="radio" name="name" />
                                <div class="radio__mark">
                                    <div class="radio__icon"></div>
                                </div>
                                </div>
                                <div class="ml-10">Any</div>
                            </div>

                            </div>

                            <div class="col-auto">
                            <div class="text-15 text-light-1">92</div>
                            </div>
                        </div>

                        <div class="row items-center justify-between">
                            <div class="col-auto">

                            <div class="form-radio d-flex items-center ">
                                <div class="radio">
                                <input type="radio" name="name" />
                                <div class="radio__mark">
                                    <div class="radio__icon"></div>
                                </div>
                                </div>
                                <div class="ml-10">Wonderful 4.5+</div>
                            </div>

                            </div>

                            <div class="col-auto">
                            <div class="text-15 text-light-1">45</div>
                            </div>
                        </div>

                        <div class="row items-center justify-between">
                            <div class="col-auto">

                            <div class="form-radio d-flex items-center ">
                                <div class="radio">
                                <input type="radio" name="name" />
                                <div class="radio__mark">
                                    <div class="radio__icon"></div>
                                </div>
                                </div>
                                <div class="ml-10">Very good 4+</div>
                            </div>

                            </div>

                            <div class="col-auto">
                            <div class="text-15 text-light-1">21</div>
                            </div>
                        </div>

                        <div class="row items-center justify-between">
                            <div class="col-auto">

                            <div class="form-radio d-flex items-center ">
                                <div class="radio">
                                <input type="radio" name="name" />
                                <div class="radio__mark">
                                    <div class="radio__icon"></div>
                                </div>
                                </div>
                                <div class="ml-10">Good 3.5+ </div>
                            </div>

                            </div>

                            <div class="col-auto">
                            <div class="text-15 text-light-1">78</div>
                            </div>
                        </div>

                        </div>
                    </div>

                    <div class="sidebar__item">
                        <h5 class="text-18 fw-500 mb-10">Style</h5>
                        <div class="sidebar-checkbox">

                        <div class="row items-center justify-between">
                            <div class="col-auto">

                            <div class="d-flex items-center">
                                <div class="form-checkbox ">
                                <input type="checkbox" name="name" />
                                <div class="form-checkbox__mark">
                                    <div class="form-checkbox__icon icon-check"></div>
                                </div>
                                </div>

                                <div class="text-15 ml-10">Budget</div>

                            </div>

                            </div>

                            <div class="col-auto">
                            <div class="text-15 text-light-1">92</div>
                            </div>
                        </div>

                        <div class="row items-center justify-between">
                            <div class="col-auto">

                            <div class="d-flex items-center">
                                <div class="form-checkbox ">
                                <input type="checkbox" name="name" />
                                <div class="form-checkbox__mark">
                                    <div class="form-checkbox__icon icon-check"></div>
                                </div>
                                </div>

                                <div class="text-15 ml-10">Mid-range </div>

                            </div>

                            </div>

                            <div class="col-auto">
                            <div class="text-15 text-light-1">45</div>
                            </div>
                        </div>

                        <div class="row items-center justify-between">
                            <div class="col-auto">

                            <div class="d-flex items-center">
                                <div class="form-checkbox ">
                                <input type="checkbox" name="name" />
                                <div class="form-checkbox__mark">
                                    <div class="form-checkbox__icon icon-check"></div>
                                </div>
                                </div>

                                <div class="text-15 ml-10">Luxury</div>

                            </div>

                            </div>

                            <div class="col-auto">
                            <div class="text-15 text-light-1">21</div>
                            </div>
                        </div>

                        <div class="row items-center justify-between">
                            <div class="col-auto">

                            <div class="d-flex items-center">
                                <div class="form-checkbox ">
                                <input type="checkbox" name="name" />
                                <div class="form-checkbox__mark">
                                    <div class="form-checkbox__icon icon-check"></div>
                                </div>
                                </div>

                                <div class="text-15 ml-10">Family-friendly </div>

                            </div>

                            </div>

                            <div class="col-auto">
                            <div class="text-15 text-light-1">78</div>
                            </div>
                        </div>

                        <div class="row items-center justify-between">
                            <div class="col-auto">

                            <div class="d-flex items-center">
                                <div class="form-checkbox ">
                                <input type="checkbox" name="name" />
                                <div class="form-checkbox__mark">
                                    <div class="form-checkbox__icon icon-check"></div>
                                </div>
                                </div>

                                <div class="text-15 ml-10">Business </div>

                            </div>

                            </div>

                            <div class="col-auto">
                            <div class="text-15 text-light-1">679</div>
                            </div>
                        </div>

                        </div>
                    </div>

                    <div class="sidebar__item">
                        <h5 class="text-18 fw-500 mb-10">Neighborhood</h5>
                        <div class="sidebar-checkbox">

                        <div class="row items-center justify-between">
                            <div class="col-auto">

                            <div class="d-flex items-center">
                                <div class="form-checkbox ">
                                <input type="checkbox" name="name" />
                                <div class="form-checkbox__mark">
                                    <div class="form-checkbox__icon icon-check"></div>
                                </div>
                                </div>

                                <div class="text-15 ml-10">Central London</div>

                            </div>

                            </div>

                            <div class="col-auto">
                            <div class="text-15 text-light-1">92</div>
                            </div>
                        </div>

                        <div class="row items-center justify-between">
                            <div class="col-auto">

                            <div class="d-flex items-center">
                                <div class="form-checkbox ">
                                <input type="checkbox" name="name" />
                                <div class="form-checkbox__mark">
                                    <div class="form-checkbox__icon icon-check"></div>
                                </div>
                                </div>

                                <div class="text-15 ml-10">Guests&#39; favourite area </div>

                            </div>

                            </div>

                            <div class="col-auto">
                            <div class="text-15 text-light-1">45</div>
                            </div>
                        </div>

                        <div class="row items-center justify-between">
                            <div class="col-auto">

                            <div class="d-flex items-center">
                                <div class="form-checkbox ">
                                <input type="checkbox" name="name" />
                                <div class="form-checkbox__mark">
                                    <div class="form-checkbox__icon icon-check"></div>
                                </div>
                                </div>

                                <div class="text-15 ml-10">Westminster Borough</div>

                            </div>

                            </div>

                            <div class="col-auto">
                            <div class="text-15 text-light-1">21</div>
                            </div>
                        </div>

                        <div class="row items-center justify-between">
                            <div class="col-auto">

                            <div class="d-flex items-center">
                                <div class="form-checkbox ">
                                <input type="checkbox" name="name" />
                                <div class="form-checkbox__mark">
                                    <div class="form-checkbox__icon icon-check"></div>
                                </div>
                                </div>

                                <div class="text-15 ml-10">Kensington and Chelsea </div>

                            </div>

                            </div>

                            <div class="col-auto">
                            <div class="text-15 text-light-1">78</div>
                            </div>
                        </div>

                        <div class="row items-center justify-between">
                            <div class="col-auto">

                            <div class="d-flex items-center">
                                <div class="form-checkbox ">
                                <input type="checkbox" name="name" />
                                <div class="form-checkbox__mark">
                                    <div class="form-checkbox__icon icon-check"></div>
                                </div>
                                </div>

                                <div class="text-15 ml-10">Oxford Street </div>

                            </div>

                            </div>

                            <div class="col-auto">
                            <div class="text-15 text-light-1">679</div>
                            </div>
                        </div>

                        </div>
                    </div>
                    </aside>
                </div>

                

                <div class="row y-gap-30 sm:pt-20">

                    {productListings.map(list => (
                        <div class="col-xl-3 col-lg-3 col-sm-6 mt-10">

                        <a href={`/temple/id/${list.id}/${list.name.toLowerCase().replace(/\s+/g, '-')}`} class="hotelsCard -type-1">

                        <div class="hotelsCard__image">
        
                            <div class="cardImage ratio ratio-1:1">
                            <div class="cardImage__content">
        
                                <img class="rounded-4 col-12" src={`https://blessingsonthenet.com/img/uploads/thumbs/${list.thumb}`} alt="image" />
        
                            </div>
        
                            </div>
        
                        </div>
        
                        <div class="hotelsCard__content mt-10 text-center">
                            <h4 class="hotelsCard__title text-dark-1 text-18 lh-16 fw-500">
                                <span className='text-blue-1'>{list.name}</span>
                            </h4>
        
                            <div>
                                <div class="fw-100">
                                    <span dangerouslySetInnerHTML={{ __html: `<p style={{lineHeight:'normal'}} class="text-dark-1 text-14 mt-10">${truncateAndStripHtml(list.scontent, 115)}...</p>` }}></span>
                                </div>
                            </div>
                        </div>
                        </a>
        
                    </div>
                    ))}

                </div>
                

                <div class="border-top-light mt-30 pt-30">

                    <div class="pt-10">
                        <div class="row justify-between">
                            <div class="col-auto">

                            </div>

                            <div className="col-auto">
                                <div className="row x-gap-20 y-gap-20 items-center pagination">
                                    {currentPage !== 1 && (
                                    <div
                                        className="col-auto pagination_numbers"
                                        onClick={() => handlePageClick(1)}
                                    >
                                        <div className="size-40 flex-center rounded-full">1</div>
                                    </div>
                                    )}
                                    {currentPage > 3 && (
                                    <div className="col-auto">
                                        <div className="size-40 flex-center rounded-full">...</div>
                                    </div>
                                    )}
                                    {Array.from({ length: totalPages }, (_, index) => {
                                    const page = index + 1;
                                    if (page === totalPages) return null; // Skip the last page number
                                    if (
                                        page < currentPage - 1 ||
                                        page > currentPage + 1 ||
                                        (currentPage === 2 && page === 1) // Skip displaying 1 when on the second page
                                    )
                                        return null;
                                    return (
                                        <div
                                        key={index}
                                        className={`col-auto pagination_numbers ${
                                            page === currentPage ? "bg-dark-1 text-white" : ""
                                        }`}
                                        onClick={() => handlePageClick(page)}
                                        >
                                        <div className="size-40 flex-center rounded-full">{page}</div>
                                        </div>
                                    );
                                    })}
                                    {currentPage < totalPages - 2 && (
                                    <div className="col-auto">
                                        <div className="size-40 flex-center rounded-full">...</div>
                                    </div>
                                    )}
                                    {currentPage !== totalPages && (
                                    <div
                                        className="col-auto pagination_numbers"
                                        onClick={() => handlePageClick(totalPages)}
                                    >
                                        <div className="size-40 flex-center rounded-full">{totalPages}</div>
                                    </div>
                                    )}
                                </div>
                            </div>


                            <div class="col-auto">
                                
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </section>

        <SubscribeRibbon />
    </>
  );
}
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { SubscribeRibbon} from './SubscribeRibbon';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Lightbox from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { getCleanedDomainName } from './urlFetch';
import {Helmet} from "react-helmet";

function Tariff() {

    // Domain details Fetch
    const [domainName, setDomainName] = useState('');

    // Fetching the domain name
    useEffect(() => {
        setDomainName(getCleanedDomainName());
    }, []);

    console.log('Cleaned Domain Name:', domainName);

    const { id, title } = useParams();

    const [microData, setMicroData] = useState({
        id: '',
        name: '',
        domain: '',
        p1: '',
        p2: '',
        p3: '',
        place: '',
    });
    useEffect(() => {
        if(id){
            const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}microsite_data?article_id=${id}`;
          console.log('API URL:', apiUrl);
    
          fetch(apiUrl)
            .then(response => response.json())
            .then(data => {
              console.log('API Response:', data);
              setMicroData(data[0]); // Assuming data is an array with a single item
            })
            .catch(error => console.error('API Error:', error));
        }
      }, [id]);

/*
    const [swiperData, setSwiperData] = useState([]);
    
    useEffect(() => {
        if(microData.id){
        const microDataUrl = `${process.env.REACT_APP_API_ENDPOINT}home_widget_ids?cat=temple_slides&id=${microData.id}`;
        console.log('microDataUrl:', microDataUrl);
        // Replace with your API URL
        fetch(microDataUrl)
            .then((response) => response.json())
            .then((data) => setSwiperData(data))
            .catch((error) => console.error('Error fetching data:', error));
        }
    }, [microData.id]);
*/

    const [hotelData, setHotelData] = useState({});
    const { 
        name
        ,short_description
        ,description
        ,hotel_image
        ,feature_images
        ,home_feature_images
        ,image_featured
        ,home_image_featured
        ,thumbnail_image
        ,city
        ,state
        ,neighborhood
        ,nighborhoodcity
        ,star_rating
        ,group
        ,logo_image
        ,location
        ,latitude
        ,longitude
        ,address
        ,zipcode
        ,type
        ,status
        ,interest_type
        ,category_type
        ,budget_type
        ,premium
        ,room
        ,available_date
        ,price
        ,roomtype
        ,available
        ,videos
        ,article
        ,featured
        ,contact_person
        ,contact_number
        ,contact_numbertw
        ,contact_address
        ,contact_person_hotel
        ,contact_number_hotel
        ,Percentage_commission_hotel
        ,special_rate
        ,email_id_hotel
        ,Address_hotel
        ,booking_office_number
        ,mobile_number_book
        ,booking_office_contact_name
        ,bank_details
        ,b2b
        ,convenience_charge
        ,convenience_charge_type
        ,facility
        ,keywords
        ,iskeyword
        ,note1
        ,note2
        ,metadesc
        ,metakey_tariff
        ,metadesc_tariff
        ,metakey_contact
        ,metadesc_contact
        ,metakey_enquiry
        ,metadesc_enquiry
        ,highlights
        ,special_notes
        ,delete_status
    } = hotelData;

    const hotelAPIURL = `${process.env.REACT_APP_API_ENDPOINT}hotel/${id}`;
    console.log("hotelAPIURL", hotelAPIURL);

    useEffect(() => {
        fetch(hotelAPIURL)
        .then(response => response.json())
        .then(data => setHotelData(data))
        .catch(error => console.error(error));
    }, []);  

    let refined_url = name ? name.toLowerCase().replace(/\s+/g, '-') : '';

    const cleanedShortDescription = short_description ? short_description.replace(/<[^>]+>/g, '').replace(/&nbsp;/g, ' ') : "";

    const cleanedDescription = description ? description
    .replace(/<p[^>]*>/g, '<p>')
    .replace(/<\/p[^>]*>/g, '</p>')
    .replace(/<(?!\/?(p|br)\b)[^>]+>/g, '')
    .replace(/&nbsp;/g, ' ') : "";


    const [swiperData, setSwiperData] = useState([]);
    useEffect(() => {
        const imageAPIURLGallery =  `${process.env.REACT_APP_API_ENDPOINT}image_retrieve?id=${id}&image_type=gallery&site_category=hotel`;
        console.log("imageAPIURLGallery: ", imageAPIURLGallery);
        fetch(imageAPIURLGallery)
        .then(response => response.json())
        .then(data => setSwiperData(data))
        .catch(errorGallery => console.errorGallery(errorGallery));
    }, []);

    
    const [thumbImages, setThumbImages] = useState([]);

    useEffect(() => {
        const imageAPIURL =  `${process.env.REACT_APP_API_ENDPOINT}image_retrieve?id=${id}&image_type=thumb&site_category=hotel`;
        fetch(imageAPIURL)
        .then(response => response.json())
        .then(data => setThumbImages(data))
        .catch(error => console.error(error));
    }, []);

    const [cityState, setCityState] = useState([]);

    useEffect(() => {
        if (city) {
        const cityStateAPIURL =  `${process.env.REACT_APP_API_ENDPOINT}cities?city_id=${city}`;
        console.log("cityStateAPIURL: ", cityStateAPIURL);
        fetch(cityStateAPIURL)
            .then(response => response.json())
            .then(data => setCityState(data))
            .catch(error => console.error(error));
        }
    }, [city]);


    let oldImage;
    if(hotel_image == ''){
        oldImage = 0;    
    } else{
        oldImage = 1;
    }
    //console.log("oldImage: ", oldImage);

    const [highlightsFetch, setHighlightsFetch] = useState([]);

    useEffect(() => {
        const highlightsUrl = `${process.env.REACT_APP_API_ENDPOINT}highlights_list`;
        fetch(highlightsUrl)
            .then(response => response.json())
            .then(data => setHighlightsFetch(data))
            .catch(error => console.log(error));
        }, []);

    const [checkedHighlights, setCheckedHighlights] = useState([]);

    useEffect(() => {
        if (highlights) {
            const highlightIds = highlights.split(',');
            setCheckedHighlights(highlightIds);
        }
    }, [highlights]);

    const [open, setOpen] = React.useState(false);

    const [hotelTypes, setHotelTypes] = useState([]);
    useEffect(() => {
        const hotelTypesUrl = `${process.env.REACT_APP_API_ENDPOINT}hotel_types/${id}`;
        console.log("hotelTypesUrl: ", hotelTypesUrl);
        fetch(hotelTypesUrl)
          .then(response => response.json())
          .then(data => setHotelTypes(data))
          .catch(error => console.error(error));
    }, [id]);

    const [thumbImagesGallery, setThumbImagesGallery] = useState([]);
    useEffect(() => {
      const imageAPIURLGallery =  `${process.env.REACT_APP_API_ENDPOINT}image_retrieve?id=${id}&image_type=gallery&site_category=hotel`;
      console.log("imageAPIURLGallery: ", imageAPIURLGallery);
      fetch(imageAPIURLGallery)
        .then(response => response.json())
        .then(data => setThumbImagesGallery(data))
        .catch(errorGallery => console.errorGallery(errorGallery));
    }, []);

    const mealTypesLabels = ['European Plan', 'American Plan', 'Modified American Plan', 'Continental Plan'];

    const getMealTypeLabel = (mealTypes) => {
    if (mealTypes) {
        const mealTypeIndices = mealTypes.split(',');
        for (let i = 0; i < mealTypeIndices.length; i++) {
        if (mealTypeIndices[i] > 0) {
            return mealTypesLabels[i];
        }
        }
    }
    return '';
    };

    const currentUrl = window.location.href;

    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        mobileNumber: '',
        noOfPersons: '',
        hotelType: '',
        startDate: '',
        endDate: '',
        message: '',
        graduationDay: ''
      });
      const [isSubmitted, setIsSubmitted] = useState(false);
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };
    
      const handleEnquirySubmit = (e) => {
        e.preventDefault();
    
        if (formData.graduationDay !== '') {
          // A value is present in the honeypot field, likely a bot
          return;
        }
    
        if (
          formData.fullName.trim() === '' ||
          formData.email.trim() === '' ||
          formData.mobileNumber.trim() === '' ||
          formData.noOfPersons.trim() === ''
        ) {
          alert('Please fill in all required fields.');
        } else {
          // Prepare the form data for submission
          const data = {
            fullName: formData.fullName,
            email: formData.email,
            mobileNumber: formData.mobileNumber,
            noOfPersons: formData.noOfPersons,
            hotelType: '',
            startDate: formData.startDate,
            endDate: formData.endDate,
            message: formData.message,
            website: domainName,
            page_title: name
          };
    
          console.log("enquiry data: ", data);
    
          // Replace 'your-api-endpoint' with your actual API endpoint
          fetch(`${process.env.REACT_APP_API_ENDPOINT}enquiry_submission`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          })
            .then((response) => {
              if (response.ok) {
                // Handle a successful response here
                setIsSubmitted(true);
              } else {
                // Handle errors here
                console.error('Form submission failed');
              }
            })
            .catch((error) => {
              console.error('Error submitting the form:', error);
            });
        }
      };

    return ( 
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{`${name} Tariff Plans`}</title>
            <link rel="canonical" href={currentUrl} />
        </Helmet>
        <div>
            <section class="py-10 d-flex items-center bg-light-2">
                <div class="container">
                    <div class="row y-gap-10 items-center justify-between">
                        <div class="col-auto">
                        <div class="row x-gap-10 y-gap-5 items-center text-14 text-light-1">
                            <div class="col-auto">
                            <div class="">Home</div>
                            </div>
                            <div class="col-auto">
                            <div class="">&gt;</div>
                            </div>
                            <div class="col-auto">
                            <div class="">Hotels</div>
                            </div>
                            <div class="col-auto">
                            <div class="">&gt;</div>
                            </div>
                            <div class="col-auto">
                            <div class="text-dark-1">{name}</div>
                            </div>
                        </div>
                        </div>

                        <div class="col-auto">
                        {/*<a href="#" class="text-14 text-blue-1 underline">View Hotels Involving Dwarka</a>*/}
                        </div>
                    </div>
                </div>
            </section>

            <section class="pt-40">
                <div class="container">
                <div class="row y-gap-20 justify-between items-end">
                    <div class="col-auto">
                    <div class="row x-gap-20  items-center">
                        <div class="col-auto">
                        <h1 class="text-30 sm:text-25 fw-600" style={{maxWidth:750,}}>{name}</h1>
                        </div>
                    </div>

                    <div class="row x-gap-20 y-gap-20 items-center">
                        <div class="col-auto">
                        <div class="d-flex items-center text-15 text-light-1">
                            {cityState.length > 0 && `${cityState[0].city}, ${cityState[0].state}`} (<b>{star_rating} Star Hotel</b>)
                        </div>
                        </div>
                    </div>
                    </div>

                    <div class="col-auto">
                    <div class="row x-gap-15 y-gap-15 items-center">
                        <div class="col-auto">
                        <div class="text-14">
                        {price !== null && price !== '0' ? (
                            <>
                            Price Starts From{' '}
                            <span className="text-22 text-dark-1 fw-500">₹{price}</span>
                            </>
                        ) : (
                            <a href={`https://www.blessingsonthenet.com/hotel/${refined_url}/${id}`} class="button h-50 px-24 -dark-1 bg-blue-1 text-white">
                                Book Now
                                <div class="icon-arrow-top-right ml-15"></div>
                            </a>
                        )}
                        </div>
                        </div>
                        
                        {price !== null && price !== '0' ? (
                        <div class="col-auto">
                        <a href="#book-now" class="button h-50 px-24 -dark-1 bg-blue-1 text-white">
                            Book Now <div class="icon-arrow-top-right ml-15"></div>
                        </a>
                        </div>
                        ) : ''}


                    </div>
                    </div>
                </div>

                </div>
            </section>

            <section class="pt-30">
                <div class="container">
                <div class="row y-gap-30">
                    <div class="col-xl-8">
                        <div class="row y-gap-40 dark_text">

                            <div className="swiper-wrapper">
                                <Swiper
                                spaceBetween={30}
                                autoplay={{
                                    delay: 5000,
                                    disableOnInteraction: false,
                                }}
                                slidesPerView={1}
                                loop={true}
                                modules={[Autoplay]}
                                className="mySwiper"
                                >
                                {swiperData.map((item) => (
                                    <SwiperSlide key={item.id}>
                                    <img style={{width:'100%'}} alt={`Slide ${item.id}`} 
                                    src={
                                        item.hotel_image.includes('aim_')
                                        ? `https://blessingsonthenet.com/img/uploads/hotels/roomgallery/${item.hotel_image}`
                                        : `https://api.blessingsonthenet.com/uploads/hotels/${item.hotel_image}`
                                    }
                                    />
                                    
                                    </SwiperSlide>
                                ))}
                                </Swiper>
                            </div>

                        </div>
                    </div>

                    <div class="col-xl-4">
                        
                        <div class="ml-50 lg:ml-0" style={{marginBottom:'20px'}}>
                            <div class="px-30 pt-20 pb-20 border-light rounded-4 shadow-4" id="book-now">
                                <div class="sidebar__item" style={{borderTop:'none'}}>
                                    <h5 class="text-20 fw-500 mb-10">Quick Links</h5>
                                    <div class="sidebar-checkbox">
                                        <table class="table-4 w-1/1 blessings_categories">
                                            <tbody>
                                                <tr>
                                                    <td>    
                                                        <a href={`/${domainName === 'shirdisaitemple.com' ? 'shirdi-hotel' : 'hotel'}/id/${id}/${refined_url}`}>
                                                            <img src={`/assets/img/general/categories-arrow.png`} />
                                                            Overview
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>    
                                                        <a className="text-blue-1" href={`/${domainName === 'shirdisaitemple.com' ? 'shirdi-hotel' : 'hotel'}/tariff/${id}/${refined_url}`}>
                                                            <img src={`/assets/img/general/categories-arrow.png`} />
                                                            Current Tariff/Packages
                                                        </a>
                                                    </td>
                                                </tr>
                                                {/*
                                                <tr>
                                                    <td>    
                                                        <a href={`https://www.blessingsonthenet.com/hotels/accommodation-enquiry/${refined_url}/${id}`}>
                                                            <img src={`/assets/img/general/categories-arrow.png`} />
                                                            Reservation Enquiry
                                                        </a>
                                                    </td>
                                                </tr>
                                                */}
                                                <tr>
                                                    <td>    
                                                        <a href={`/${domainName === 'shirdisaitemple.com' ? 'shirdi-hotel' : 'hotel'}/contact-info/${id}/${refined_url}`}>
                                                            <img src={`/assets/img/general/categories-arrow.png`} />
                                                            Contact Us
                                                        </a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="ml-50 lg:ml-0">

                            <div class="px-30 pt-10 pb-20 border-light rounded-4 shadow-4" id="book-now">
                                <div class="d-flex items-center justify-between">
                                    <div class="">
                                    <span class="text-20 fw-500">Book Now</span>
                                    </div>
                                </div>

                                <div className="text-center">
                                    <br />
                                    <h6 style={{color:'#FF0000'}}>WhatsApp for Immediate Response and Customization</h6>
                                    <br />
                                    {/*
                                    <a href="https://wa.me/918452040404">+91 8452 040 404</a><br />
                                    <a href="https://wa.me/919224444455">+91 9224 444 455</a>
                                    */}
                                    <a href="https://wa.me/918452040404">+91 8452 040 404</a><br />
                                    <a href="https://wa.me/919224444455">+91 9224 444 455</a>
                                    <br /><br />
                                    <h6 style={{color:'#FF0000'}}>Send Us Email</h6>
                                    <a href="mailto:travel@blessingsonthenet.com">travel@blessingsonthenet.com</a><br /><br />
                                    
                                    <a href={`https://www.blessingsonthenet.com/hotel/${refined_url}/${id}`} class="button h-50 px-24 -dark-1 bg-blue-1 text-white">
                                        Book Now
                                        <div class="icon-arrow-top-right ml-15"></div>
                                    </a><br />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                </div>
            </section>

            <section id="rooms" class="pt-20 mb-80">
                <div class="container">
                <div class="row pb-20">
                    <div class="col-auto">
                    <h3 class="text-22 fw-500">Room Types</h3>
                    </div>
                </div>
                
                {hotelTypes.length > 0 && hotelTypes.map(page => (
                <div class="border-light rounded-4 px-30 py-30 sm:px-20 sm:py-20" style={{marginBottom:'20px'}}>
                    <div class="row y-gap-20">
                    <div class="col-12">
                        <h3 class="text-18 fw-500 mb-15">{page.room_type}</h3>
                        <div class="roomGrid">
                        <div class="roomGrid__header">
                            <div>Room Type</div>
                            <div>Benefits</div>
                            <div>Members</div>
                            <div>Price for 1 Night</div>
                            <div></div>
                        </div>

                        <div class="roomGrid__grid">
                            <div>
                            <div class="ratio ratio-1:1">

                                {/*
                                <img
                                src={`https://blessingsonthenet.com/img/uploads/hotels/${hotel_image}`}
                                alt="Hotel Room"
                                class="img-ratio rounded-4"
                                onClick={() => setOpen(true)}
                                />
                                */}

                                {thumbImagesGallery.slice(0, 1).map((image, index) => (
                                <div key={index}>
                                    <img
                                    src={image.hotel_image.includes('aim_')
                                    ? `https://blessingsonthenet.com/img/uploads/hotels/roomgallery/${image.hotel_image}`
                                    : `${process.env.REACT_APP_API_ENDPOINT}uploads/hotels/${image.hotel_image}`}
                                    alt="image"
                                    className="img-ratio rounded-4"
                                    onClick={() => setOpen(true)}
                                    />
                                </div>
                                ))}

                            </div>

                            <div class="y-gap-5 mt-20">
                                {getMealTypeLabel(page.meal_types) && (
                                <div class="d-flex items-center">
                                    <i class="icon-food text-20 mr-10"></i>
                                    <div class="text-15">{getMealTypeLabel(page.meal_types)}</div>
                                </div>
                                )}
                            </div>
                    
                            {/*
                            <div class="y-gap-5 mt-20">

                                <div class="d-flex items-center">
                                <i class="icon-no-smoke text-20 mr-10"></i>
                                <div class="text-15">{page.other_facility} Non-smoking rooms</div>
                                </div>

                                <div class="d-flex items-center">
                                <i class="icon-wifi text-20 mr-10"></i>
                                <div class="text-15">Free WiFi</div>
                                </div>

                                <div class="d-flex items-center">
                                <i class="icon-parking text-20 mr-10"></i>
                                <div class="text-15">Parking</div>
                                </div>

                                <div class="d-flex items-center">
                                <i class="icon-kitchen text-20 mr-10"></i>
                                <div class="text-15">Kitchen</div>
                                </div>

                            </div>
                            */}
                            </div>

                            <div class="y-gap-30">

                            <div class="roomGrid__content">
                                <div>

                                <div class="y-gap-8">

                                    <div>
                                    {page.available_facility && page.available_facility.trim() !== '' && (
                                        page.available_facility.split(',').map((item, index) => (
                                        <div className="d-flex items-center text-green-2" key={index}>
                                            <i className="icon-check text-12 mr-10"></i>
                                            <div className="text-15">{item.trim()}</div>
                                        </div>
                                        ))
                                    )}

                                    {page.other_facility && page.other_facility.trim() !== '' && (
                                        page.other_facility.split(',').map((item, index) => (
                                        <div className="d-flex items-center text-green-2" key={index}>
                                            <i className="icon-check text-12 mr-10"></i>
                                            <div className="text-15">{item.trim()}</div>
                                        </div>
                                        ))
                                    )}
                                    </div>

                                </div>
                                </div>

                                <div>
                                <div class="d-flex items-center text-light-1">
                                    <div class="icon-man text-24"></div>
                                    <div class="icon-man text-24"></div>
                                </div>
                                </div>

                                <div>
                                <div class="text-18 lh-15 fw-500">₹{page.price}</div>
                                <div class="text-14 lh-18 text-light-1">{page.tax}% tax extra</div>
                                </div>
                            </div>

                            {page.description.length > 0 && (
                            <div class="y-gap-5 grey_border_top">
                                <div dangerouslySetInnerHTML={{ __html: page.description }} />
                            </div>
                            )}

                            </div>

                            <div>
                            <div class="text-14 lh-1">1 Room (accomodates 2 people)</div>
                            <div class="text-22 fw-500 lh-17 mt-5">₹{page.price}</div>


                            <a href={`https://www.blessingsonthenet.com/hotel/${refined_url}/${id}`} class="button h-50 px-24 -dark-1 bg-blue-1 text-white mt-10">
                                Book Now <div class="icon-arrow-top-right ml-15"></div>
                            </a>


                            <div class="text-15 fw-500 mt-30">Additional People Cost</div>

                            <ul class="list-disc y-gap-4 pt-5">

                                <li class="text-14">Extra Person: ₹{page.extra_person_charge ? page.extra_person_charge : 0}</li>

                                <li class="text-14">Child: ₹{page.child_price ? page.child_price : 0}</li>

                            </ul>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                ))}
                
                </div>
            </section>

            <section class="layout-pt-lg layout-pb-md mt-50">
                <div data-anim-wrap="" class="container animated">
                    <div data-anim-child="slide-up delay-1" class="row justify-center text-center is-in-view">
                    <div class="col-auto">
                        <div class="sectionTitle -md">
                        <h2 class="sectionTitle__title">Enquire with Us</h2>
                        <p class=" sectionTitle__text mt-5 sm:mt-0">Have any questions? feel free to get in touch with us and we will answer all your questions.</p>
                        </div>
                    </div>
                    </div>

                    <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-6">
                        <div style={{backgroundColor: '#13357B', color: '#fff',}} class="mt-50 px-40 pt-30 pb-30 lg:px-30 lg:py-30 md:px-24 md:py-24 rounded-4 shadow-4">
                            <div class="card-body">
                            <h4 style={{color: '#fff',}} class="card-title">WhatsApp Us</h4><br />
                            <p style={{color: '#fff',}} class="card-text">Request a quote, or just chat about your next vacation. We're always happy to help!</p><br />
                            {/*
                            <a target="_blank" href="https://wa.me/919892002006"><h5 style={{color: '#fff',}} class="card-text mb-10"><img src="/assets/img/icons/phone.png" style={{width:'25px'}} /> +91 9892 002 006</h5></a>
                            <a target="_blank" href="https://wa.me/919224444455"><h5 style={{color: '#fff',}} class="card-text mb-10"><img src="/assets/img/icons/phone.png" style={{width:'25px'}} /> +91 9224 444 455</h5></a>
                            <a target="_blank" href="https://wa.me/919987404440"><h5 style={{color: '#fff',}} class="card-text mb-10"><img src="/assets/img/icons/phone.png" style={{width:'25px'}} /> +91 9987 404 440</h5></a>
                            */}
                            <a target="_blank" href="https://wa.me/919892002006"><h5 style={{color: '#fff',}} class="card-text mb-10"><img src="/assets/img/icons/phone.png" style={{width:'25px'}} /> +91 9892 002 006</h5></a>
                            <a target="_blank" href="https://wa.me/919224444455"><h5 style={{color: '#fff',}} class="card-text mb-10"><img src="/assets/img/icons/phone.png" style={{width:'25px'}} /> +91 9224 444 455</h5></a>
                            <a target="_blank" href="https://wa.me/919987404440"><h5 style={{color: '#fff',}} class="card-text mb-10"><img src="/assets/img/icons/phone.png" style={{width:'25px'}} /> +91 9987 404 440</h5></a>
                            </div>
                        </div>
                        <div style={{backgroundColor: '#ff894c', color:'#051036'}} class="mt-50 px-40 pt-40 pb-50 lg:px-30 lg:py-30 md:px-24 md:py-24 rounded-4 shadow-4">
                            <div class="card-body">
                            <h4 style={{color:'#051036'}} class="card-title">Write to Us</h4><br />
                            <p style={{color:'#051036'}} class="card-text">Do you like to customize this hotel stay even more or be it an enquiry, feedback or a simple suggestion, write to us.</p><br />
                            <a target="_blank" href="mailto:travel@blessingsonthenet.com"><h5 style={{color:'#051036'}} class="card-text"><img src="/assets/img/icons/email.png" style={{width:'25px'}} /> travel@blessingsonthenet.com</h5></a>
                            </div>
                        </div>
                        </div>
                        <div class="col-md-6">
                        <div className="mt-50 px-40 pt-35 pb-40 lg:px-30 lg:py-30 md:px-24 md:py-24 bg-white rounded-4 shadow-4">
                        <div className="text-22 fw-500">Explore Our Exclusive Hotel Deals!</div>
                            <div className="row y-gap-15 pt-20">
                            <div className="col-12">
                                <div className="form-input">
                                <input
                                    type="text"
                                    name="fullName"
                                    value={formData.fullName}
                                    onChange={handleChange}
                                    required
                                />
                                <label className="lh-1 text-16 text-light-1">Full Name*</label>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-input">
                                <input
                                    type="text"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                />
                                <label className="lh-1 text-16 text-light-1">Email Address*</label>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-input">
                                <input
                                    type="text"
                                    name="mobileNumber"
                                    value={formData.mobileNumber}
                                    onChange={handleChange}
                                    required
                                />
                                <label className="lh-1 text-16 text-light-1">Mobile Number*</label>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-input">
                                <input
                                    type="text"
                                    name="noOfPersons"
                                    value={formData.noOfPersons}
                                    onChange={handleChange}
                                    required
                                />
                                <label className="lh-1 text-16 text-light-1">No. of Person*</label>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-input">
                                <input
                                    type="date"
                                    name="startDate"
                                    value={formData.startDate}
                                    onChange={handleChange}
                                    required
                                />
                                <label className="lh-1 text-16 text-light-1" style={{top:'15px'}}>Start Date</label>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-input">
                                <input
                                    type="date"
                                    name="endDate"
                                    value={formData.endDate}
                                    onChange={handleChange}
                                    required
                                />
                                <label className="lh-1 text-16 text-light-1" style={{top:'15px'}}>End Date</label>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-input">
                                <textarea
                                    name="message"
                                    value={formData.message}
                                    onChange={handleChange}
                                    required
                                    rows="2"
                                ></textarea>
                                <label className="lh-1 text-16 text-light-1">Message (If Any)</label>
                                </div>
                            </div>
                            <div style={{ display: 'none' }}>
                                <input
                                type="text"
                                name="graduationDay"
                                value={formData.graduationDay}
                                onChange={handleChange}
                                />
                            </div>
                            <div className="col-auto">
                                <button
                                onClick={handleEnquirySubmit}
                                className="button px-24 h-50 -dark-1 bg-blue-1 text-white"
                                >
                                Send a Message
                                <div className="icon-arrow-top-right ml-15"></div>
                                </button>
                            </div>
                            <div className="col-12">
                                {isSubmitted && <p style={{color:'green'}}>Thanks for submitting your details, we will get back to you shortly!</p>}
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    
                </div>
            </section>

            <SubscribeRibbon />
        </div>
        </>
    );
}

export default Tariff;
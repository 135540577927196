import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { SubscribeRibbon } from './SubscribeRibbon';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { getCleanedDomainName } from './urlFetch';
import {Helmet} from "react-helmet";

function TourListings() {

    // Domain details Fetch
    const [domainName, setDomainName] = useState('');

    // Fetching the domain name
    useEffect(() => {
        setDomainName(getCleanedDomainName());
    }, []);

    console.log('Cleaned Domain Name:', domainName);

    const [microData, setMicroData] = useState({
        id: '',
        name: '',
        domain: '',
        p1: '',
        p2: '',
        p3: '',
        place: '',
    });
    const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}microsite_data/${domainName}`;

    useEffect(() => {
        if(domainName){
        console.log('API URL:', apiUrl);

        fetch(apiUrl)
            .then(response => response.json())
            .then(data => {
            console.log('API Response:', data);
            setMicroData(data[0]); // Assuming data is an array with a single item
            })
            .catch(error => console.error('API Error:', error));
        }
    }, [domainName, apiUrl]);

    //console.log('Name:', microData.name);
    //console.log('Domain:', microData.domain);
    console.log('P1:', microData.p1);
    console.log('P2:', microData.p2);
    console.log('P3:', microData.p3);


    const [swiperData, setSwiperData] = useState([]);
    
    useEffect(() => {
        if(microData.id){
        const microDataUrl = `${process.env.REACT_APP_API_ENDPOINT}home_widget_ids?cat=temple_slides&id=${microData.id}`;
        console.log('microDataUrl:', microDataUrl);
        // Replace with your API URL
        fetch(microDataUrl)
            .then((response) => response.json())
            .then((data) => setSwiperData(data))
            .catch((error) => console.error('Error fetching data:', error));
        }
    }, [microData.id]);




    

    const location = useLocation();
    const navigate = useNavigate();
    
    const { id, title, keyword, categoryId, state } = useParams();
    const [holidayListings, setHolidayListings] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [priceFilter, setPriceFilter] = useState("");
    const [durationFilter, setDurationFilter] = useState("");
    const [showWithoutPrice, setShowWithoutPrice] = useState(true);
    const [totalCount, setTotalCount] = useState(0);
    const [galleryImages, setGalleryImages] = useState([]);

    console.log("title: ", title);
    
    useEffect(() => {
        if(domainName){
            let page = id ? parseInt(id) : currentPage; // Use the value from the URL if present
            setCurrentPage(page);
            
            const searchParams = new URLSearchParams(location.search);
            
            if (keyword) {
                searchParams.set('keyword', keyword);
            }
            if (priceFilter) {
                searchParams.set('price', priceFilter);
            }
            if (durationFilter) {
                searchParams.set('duration', durationFilter);
            }
            if (showWithoutPrice) {
                searchParams.set('shp', '1');
            } else {
                searchParams.set('shp', '0');
            }

            const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}microsite_tour_listings/${microData.id}?page=${page}`;
            console.log("apiUrl: ", apiUrl);      
            
            fetch(apiUrl)
                .then((response) => response.json())
                .then((data) => {
                    setHolidayListings(data);
                    setTotalCount(data.length > 0 ? data[0].totalCount : 0);
                    const totalPages = Math.ceil(data[0].totalCount / 10);
                    console.log("totalCount: ", data[0].totalCount);
                    setTotalPages(totalPages);
                })
                .catch((error) => console.log(error));

                // Fetch gallery images
                const galleryImagesURL = `${process.env.REACT_APP_API_ENDPOINT}microsite_temple_galleries/${microData.id}`;
                fetch(galleryImagesURL)
                .then(response => response.json())
                .then(data => setGalleryImages(data))
                .catch(error => console.error(error));
        }
    }, [id, currentPage, keyword, priceFilter, durationFilter, showWithoutPrice, categoryId, state, totalCount, microData.id]);

    const handlePageClick = (page) => {
        setCurrentPage(page);
      
        const path = location.pathname;
        const currentQueryParams = new URLSearchParams(location.search);
        currentQueryParams.delete('page');
      
        let newPath = path;
      
        if (path.includes('/page/')) {
          newPath = path.replace(/\/page\/\d+/, `/page/${page}`);
        } else {
          newPath = `${path}/page/${page}`;
        }
      
        const newURL = `${newPath}${currentQueryParams.toString() ? `?${currentQueryParams.toString()}` : ''}`;
        navigate(newURL);

         // Scroll to the top of the page
         window.scrollTo({
            top: 600,
            behavior: 'smooth',
        });
      };            

      const currentUrl = window.location.href;

    return ( 
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{`${microData.place} Tour Packages`}</title>
            <link rel="canonical" href={currentUrl} />
        </Helmet>
        <div>

            {/* Slider section */}
            <section className="z-5">
                <div className="swiper-wrapper">
                    <Swiper
                    spaceBetween={30}
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: false,
                    }}
                    slidesPerView={1}
                    loop={true}
                    modules={[Autoplay]}
                    className="mySwiper"
                    >
                    {swiperData.map((item) => (
                        <SwiperSlide key={item.id}>
                        <img style={{width:'100%'}} src={
                            item.image.includes('aim_')
                            ? `https://blessingsonthenet.com/img/slideshow/${item.image}`
                            : `https://api.blessingsonthenet.com/uploads/microsite/${item.image}`
                            } alt={`Slide ${item.id}`} />
                        </SwiperSlide>
                    ))}
                    </Swiper>
                </div>
            </section>

            <section class="layout-pt-md layout-pb-lg">
                <div class="container">

                    <div class="row items-center justify-between">
                        <div class="col-auto">
                            <span class="fw-500 text-25">Tour Packages</span>
                        </div>
                    </div>

                    <div class="col-12 row">
                        {holidayListings.map((list, index) => (
                            <div class="col-md-6 col-sm-12 mt-30" key={list.id}>
                            <div class="row y-gap-30">
                                <div class="col-12">
                                <div class="border-top-light pt-30">
                                    <div class="row x-gap-20 y-gap-20">
                                    <div class="col-md-4 col-sm-12">
                                        <a href={`/${domainName === 'shirdisaitemple.com' ? 'shirdi-article' : 'article'}/id/${list.id}/${list.title.toLowerCase().replace(/\s+/g, '-')}`}>
                                        <div class="cardImage ratio ratio-1:1 w-250 md:w-1/1 rounded-4">
                                            <div class="cardImage__content">
                                            {galleryImages[index % galleryImages.length] && (
                                                <img src={`https://blessingsonthenet.com/img/gallery/${galleryImages[index % galleryImages.length].thumb}`} alt={list.title} />
                                            )}
                                            </div>
                                        </div>
                                        </a>
                                    </div>
                                    <div class="col-8">
                                        <a href={`/${domainName === 'shirdisaitemple.com' ? 'shirdi-article' : 'article'}/id/${list.id}/${list.title.toLowerCase().replace(/\s+/g, '-')}`}>
                                        <h3 class="text-18 lh-16 fw-500">{list.title}<br class="lg:d-none" /></h3>
                                        </a>
                                        <div class="text-14 lh-15 mt-20" style={{ minHeight: '95px' }}>
                                        <div class="fw-500" dangerouslySetInnerHTML={{ __html: list.scontent.replace(/<[^>]+>/g, '').replace(/&nbsp;/g, ' ').slice(0, 240) + "..." }} />
                                        </div>
                                        <div class="col-12">
                                        <div class="text-14 text-green-2 lh-15 mt-15 border-top-light">
                                            <div class="mt-10">WhatsApp <b>+91 9892 002 006</b> to customize this Holiday.</div>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        ))}
                    </div>


                    <div class="row y-gap-30">

                        <div class="col-12">

                        <div class="border-top-light mt-20 pt-30">

                            <div class="pt-10">
                                <div class="row justify-between">
                                    <div class="col-auto">

                                    </div>

                                    <div className="col-auto">
                                        <div className="row x-gap-20 y-gap-20 items-center pagination">
                                            {currentPage !== 1 && (
                                            <div
                                                className="col-auto pagination_numbers"
                                                onClick={() => handlePageClick(1)}
                                            >
                                                <div className="size-40 flex-center rounded-full">1</div>
                                            </div>
                                            )}
                                            {currentPage > 3 && (
                                            <div className="col-auto">
                                                <div className="size-40 flex-center rounded-full">...</div>
                                            </div>
                                            )}
                                            {Array.from({ length: totalPages }, (_, index) => {
                                            const page = index + 1;
                                            if (page === totalPages) return null; // Skip the last page number
                                            if (
                                                page < currentPage - 1 ||
                                                page > currentPage + 1 ||
                                                (currentPage === 2 && page === 1) // Skip displaying 1 when on the second page
                                            )
                                                return null;
                                            return (
                                                <div
                                                key={index}
                                                className={`col-auto pagination_numbers ${
                                                    page === currentPage ? "bg-dark-1 text-white" : ""
                                                }`}
                                                onClick={() => handlePageClick(page)}
                                                >
                                                <div className="size-40 flex-center rounded-full">{page}</div>
                                                </div>
                                            );
                                            })}
                                            {currentPage < totalPages - 2 && (
                                            <div className="col-auto">
                                                <div className="size-40 flex-center rounded-full">...</div>
                                            </div>
                                            )}
                                            {currentPage !== totalPages && (
                                            <div
                                                className="col-auto pagination_numbers"
                                                onClick={() => handlePageClick(totalPages)}
                                            >
                                                <div className="size-40 flex-center rounded-full">{totalPages}</div>
                                            </div>
                                            )}
                                        </div>
                                    </div>


                                    <div class="col-auto">
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </section>
            <SubscribeRibbon />
        </div>
        </>
    );
}

export default TourListings;
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './routes';
import Header from './components/Header';
import Footer from './components/Footer';

function App() {
  return (
    <body>
      <div class="preloader js-preloader">
        {/*
        <div class="preloader__wrap">
          <div class="preloader__icon">
            <img src="/assets/img/general/logo-icon.png" />
          </div>
        </div>

        <div class="preloader__title">NIRVANA INDIA</div>
        */}
      </div>
      <main>
        <div class="header-margin"></div>
        <Header />
          <Router>
            <Routes />
          </Router>
        <Footer />
      </main>
    </body>
  );
}

export default App;
